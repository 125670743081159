export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  Date: any;
  LocalDate: any;
  LocalDateTime: any;
};

export type AuditInfo = {
  __typename?: 'AuditInfo';
  createdBy: Scalars['String'];
  created: Scalars['LocalDateTime'];
  updatedBy: Scalars['String'];
  updated: Scalars['LocalDateTime'];
};


export type Body = CodetableDto & {
  __typename?: 'Body';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  vehicleType: VehicleType;
  state?: Maybe<CodetableStateEnum>;
  /** Contains unique code of the body */
  code: Scalars['String'];
};

export type BodyCreateDto = {
  /**
   * Contains unique string value chosen by user in the system.
   * eg.: COMBI, CHASI
   * system validates combination code-vehicleTypeId
   * this value is automatically converted to uppercase
   */
  code: Scalars['ID'];
  labels?: Maybe<Array<CodetableLabelCreateDto>>;
  vehicleTypeId: Scalars['ID'];
};

export type BodyPayload = ResponsePayload & {
  __typename?: 'BodyPayload';
  errors?: Maybe<Array<BusinessError>>;
  data?: Maybe<Body>;
};

export type BodyUpdateDto = {
  /**
   * Contains unique identifier in the system
   * this value is automatically converted to uppercase
   */
  id: Scalars['ID'];
  newLabels?: Maybe<Array<CodetableLabelCreateDto>>;
  updatedLabels?: Maybe<Array<CodetableLabelUpdateDto>>;
  vehicleTypeId: Scalars['ID'];
  state?: Maybe<CodetableStateEnum>;
};

export type Bonus = {
  __typename?: 'Bonus';
  id: Scalars['ID'];
  auditInfo: AuditInfo;
  name: Scalars['String'];
  value: Scalars['BigDecimal'];
  unit: BonusUnitEnum;
  brand?: Maybe<Brand>;
  model?: Maybe<Scalars['String']>;
  vehicleType?: Maybe<VehicleType>;
  equipment?: Maybe<Scalars['String']>;
  engine?: Maybe<Scalars['String']>;
  fourDriveWheel?: Maybe<Scalars['Boolean']>;
  validTo?: Maybe<Scalars['LocalDate']>;
  forCrn?: Maybe<Scalars['Boolean']>;
  forPhysicalPerson?: Maybe<Scalars['Boolean']>;
};

export type BonusCreateInput = {
  name: Scalars['String'];
  value: Scalars['BigDecimal'];
  unit: BonusUnitEnum;
  brandId?: Maybe<Scalars['ID']>;
  model?: Maybe<Scalars['String']>;
  vehicleTypeId?: Maybe<Scalars['ID']>;
  equipment?: Maybe<Scalars['String']>;
  engine?: Maybe<Scalars['String']>;
  fourDriveWheel?: Maybe<Scalars['Boolean']>;
  validTo?: Maybe<Scalars['LocalDate']>;
  forCrn?: Maybe<Scalars['Boolean']>;
  forPhysicalPerson?: Maybe<Scalars['Boolean']>;
};

export type BonusFilter = {
  brandId?: Maybe<Scalars['ID']>;
  model?: Maybe<Scalars['String']>;
  vehicleTypeId?: Maybe<Scalars['ID']>;
  equipment?: Maybe<Scalars['String']>;
  engine?: Maybe<Scalars['String']>;
  fourDriveWheel?: Maybe<Scalars['Boolean']>;
  validTo?: Maybe<Scalars['LocalDate']>;
  forCrn?: Maybe<Scalars['Boolean']>;
  forPhysicalPerson?: Maybe<Scalars['Boolean']>;
};

export type BonusPayload = ResponsePayload & {
  __typename?: 'BonusPayload';
  errors?: Maybe<Array<BusinessError>>;
  bonus?: Maybe<Bonus>;
};

export enum BonusUnitEnum {
  Czk = 'CZK',
  Percentage = 'PERCENTAGE'
}

export type BonusUpdateInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  value: Scalars['BigDecimal'];
  unit: BonusUnitEnum;
  brandId?: Maybe<Scalars['ID']>;
  model?: Maybe<Scalars['String']>;
  vehicleTypeId?: Maybe<Scalars['ID']>;
  equipment?: Maybe<Scalars['String']>;
  engine?: Maybe<Scalars['String']>;
  fourDriveWheel?: Maybe<Scalars['Boolean']>;
  validTo?: Maybe<Scalars['LocalDate']>;
  forCrn?: Maybe<Scalars['Boolean']>;
  forPhysicalPerson?: Maybe<Scalars['Boolean']>;
};

export type Brand = CodetableDto & {
  __typename?: 'Brand';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  state?: Maybe<CodetableStateEnum>;
};

export enum BrandEnum {
  Ford = 'FORD',
  Skoda = 'SKODA',
  Vwg = 'VWG',
  Volvo = 'VOLVO',
  Toyota = 'TOYOTA'
}

export type BrandPayload = ResponsePayload & {
  __typename?: 'BrandPayload';
  errors?: Maybe<Array<BusinessError>>;
  data?: Maybe<Brand>;
};

export type BusinessError = {
  __typename?: 'BusinessError';
  code?: Maybe<Scalars['String']>;
};

export type CardColorDeletePayload = DeletePayload & {
  __typename?: 'CardColorDeletePayload';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<BusinessError>>;
};

export type ChangePasswordInput = {
  password: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type CodetableCreateDto = {
  /**
   * Contains unique string value chosen by user in the system.
   * eg.: SKODA, FORD
   * system validates uniqueness of this value
   * this value is automatically converted to uppercase
   */
  id: Scalars['ID'];
  labels?: Maybe<Array<CodetableLabelCreateDto>>;
};

export type CodetableDto = {
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  state?: Maybe<CodetableStateEnum>;
};

export type CodetableLabelCreateDto = {
  label: Scalars['String'];
  language: LanguageEnum;
};

export type CodetableLabelUpdateDto = {
  label: Scalars['String'];
  language: LanguageEnum;
};

export enum CodetableStateEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED'
}

export type CodetableUpdateDto = {
  /**
   * Contains unique value to identify existing entry n the system
   * EG: SKODA
   * ID value is automatically converted to uppercase
   */
  id: Scalars['ID'];
  newLabels?: Maybe<Array<CodetableLabelCreateDto>>;
  updatedLabels?: Maybe<Array<CodetableLabelUpdateDto>>;
  state?: Maybe<CodetableStateEnum>;
};

export type Color = CodetableDto & {
  __typename?: 'Color';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  state?: Maybe<CodetableStateEnum>;
};

export type ColorPayload = ResponsePayload & {
  __typename?: 'ColorPayload';
  errors?: Maybe<Array<BusinessError>>;
  data?: Maybe<Color>;
};

export type ColorType = CodetableDto & {
  __typename?: 'ColorType';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  state?: Maybe<CodetableStateEnum>;
};

export type ColorTypePayload = ResponsePayload & {
  __typename?: 'ColorTypePayload';
  errors?: Maybe<Array<BusinessError>>;
  data?: Maybe<ColorType>;
};

export type CountryOfOrigin = CodetableDto & {
  __typename?: 'CountryOfOrigin';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  state?: Maybe<CodetableStateEnum>;
};

export type CountryOfOriginPayload = ResponsePayload & {
  __typename?: 'CountryOfOriginPayload';
  errors?: Maybe<Array<BusinessError>>;
  data?: Maybe<CountryOfOrigin>;
};

export type Credit = {
  __typename?: 'Credit';
  servicePackage: ServicePackage;
  extension?: Maybe<Scalars['String']>;
  creditGroups?: Maybe<Array<Maybe<CreditGroup>>>;
};

export type CreditGroup = {
  __typename?: 'CreditGroup';
  type: CreditTypeEnum;
  items?: Maybe<Array<CreditGroupItem>>;
};

export type CreditGroupInput = {
  type: CreditTypeEnum;
  items?: Maybe<Array<CreditGroupItemInput>>;
};

export type CreditGroupItem = {
  __typename?: 'CreditGroupItem';
  month?: Maybe<Scalars['Int']>;
  monthlyInstalment?: Maybe<Scalars['BigDecimal']>;
  advancePayment?: Maybe<Scalars['BigDecimal']>;
};

export type CreditGroupItemInput = {
  month?: Maybe<Scalars['Int']>;
  monthlyInstalment?: Maybe<Scalars['BigDecimal']>;
  advancePayment?: Maybe<Scalars['BigDecimal']>;
};

export type CreditInput = {
  serviceId: Scalars['ID'];
  extension?: Maybe<Scalars['String']>;
  creditGroups?: Maybe<Array<Maybe<CreditGroupInput>>>;
};

export enum CreditTypeEnum {
  Crn = 'CRN',
  PhysicalPerson = 'PHYSICAL_PERSON'
}

export enum CurrencyEnum {
  Eur = 'EUR',
  Czk = 'CZK'
}

export type CustomAttribute = {
  __typename?: 'CustomAttribute';
  id: Scalars['ID'];
  value: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  dataType: CustomAttributeDataTypeEnum;
  label?: Maybe<Scalars['String']>;
};

export enum CustomAttributeDataTypeEnum {
  String = 'STRING',
  Boolean = 'BOOLEAN',
  Int = 'INT',
  Float = 'FLOAT',
  Date = 'DATE',
  DateTime = 'DATE_TIME'
}

export type CustomAttributeInput = {
  id: Scalars['ID'];
  value: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  dataType: CustomAttributeDataTypeEnum;
  label?: Maybe<Scalars['String']>;
};


export type DateFilter = {
  from?: Maybe<Scalars['LocalDate']>;
  to?: Maybe<Scalars['LocalDate']>;
};

export type DateTimeFilter = {
  /** Attributed used to passed `from` date-time. Expects ISO 8601 format. */
  from?: Maybe<Scalars['LocalDateTime']>;
  /** Attributed used to passed `to` date-time. Expects ISO 8601 format. */
  to?: Maybe<Scalars['LocalDateTime']>;
};

export type DeferredCredit = {
  __typename?: 'DeferredCredit';
  servicePackage: ServicePackage;
  extension?: Maybe<Scalars['String']>;
  deferredCreditGroups?: Maybe<Array<Maybe<DeferredCreditGroup>>>;
};

export type DeferredCreditGroup = {
  __typename?: 'DeferredCreditGroup';
  type: CreditTypeEnum;
  items?: Maybe<Array<DeferredCreditGroupItem>>;
};

export type DeferredCreditGroupInput = {
  type: CreditTypeEnum;
  items?: Maybe<Array<DeferredCreditGroupItemInput>>;
};

export type DeferredCreditGroupItem = {
  __typename?: 'DeferredCreditGroupItem';
  month?: Maybe<Scalars['Int']>;
  monthlyInstalment?: Maybe<Scalars['BigDecimal']>;
  advancePayment?: Maybe<Scalars['BigDecimal']>;
  deferredPayment?: Maybe<Scalars['BigDecimal']>;
  purchasePrice?: Maybe<Scalars['BigDecimal']>;
};

export type DeferredCreditGroupItemInput = {
  month?: Maybe<Scalars['Int']>;
  monthlyInstalment?: Maybe<Scalars['BigDecimal']>;
  advancePayment?: Maybe<Scalars['BigDecimal']>;
  deferredPayment?: Maybe<Scalars['BigDecimal']>;
  purchasePrice?: Maybe<Scalars['BigDecimal']>;
};

export type DeferredCreditInput = {
  serviceId: Scalars['ID'];
  extension?: Maybe<Scalars['String']>;
  deferredCreditGroups?: Maybe<Array<Maybe<DeferredCreditGroupInput>>>;
};

export type DeletePayload = {
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<BusinessError>>;
};

export type Equipment = {
  __typename?: 'Equipment';
  id: Scalars['ID'];
  auditInfo: AuditInfo;
  name: Scalars['String'];
  category: EquipmentCategory;
};

export type EquipmentCategory = CodetableDto & {
  __typename?: 'EquipmentCategory';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  state?: Maybe<CodetableStateEnum>;
};

export type EquipmentCategoryPayload = ResponsePayload & {
  __typename?: 'EquipmentCategoryPayload';
  errors?: Maybe<Array<BusinessError>>;
  data?: Maybe<EquipmentCategory>;
};

export type EquipmentCreateInput = {
  name: Scalars['String'];
  categoryId: Scalars['ID'];
};

export type EquipmentItem = {
  __typename?: 'EquipmentItem';
  id: Scalars['ID'];
  equipment: Equipment;
  count?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};

export type EquipmentItemCreateInput = {
  equipmentId: Scalars['ID'];
  count?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  modelEquipmentInputId: Scalars['ID'];
};

export type EquipmentItemDeleteInput = {
  id: Scalars['ID'];
};

export type EquipmentItemPayload = ResponsePayload & {
  __typename?: 'EquipmentItemPayload';
  errors?: Maybe<Array<BusinessError>>;
  equipmentItem?: Maybe<EquipmentItem>;
};

export type EquipmentItemUpdateInput = {
  id: Scalars['ID'];
  count?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};

export type EquipmentPayload = ResponsePayload & {
  __typename?: 'EquipmentPayload';
  errors?: Maybe<Array<BusinessError>>;
  equipment?: Maybe<Equipment>;
};

export type EquipmentUpdateInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  categoryId: Scalars['ID'];
};

export type ExternalSystem = {
  __typename?: 'ExternalSystem';
  id: Scalars['ID'];
  system: Scalars['String'];
  inserted?: Maybe<Scalars['Date']>;
};

export type ExternalSystemInput = {
  id: Scalars['ID'];
  system: Scalars['String'];
  inserted?: Maybe<Scalars['Date']>;
};

export type ExtraEquipmentItem = {
  __typename?: 'ExtraEquipmentItem';
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['BigDecimal']>;
};

export type ExtraEquipmentItemInput = {
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['BigDecimal']>;
};

export type FinanceGroup = {
  __typename?: 'FinanceGroup';
  groupPrice: Scalars['BigDecimal'];
  lease?: Maybe<Leasing>;
  credit?: Maybe<Credit>;
  deferredCredit?: Maybe<DeferredCredit>;
};

export type FinanceGroupInput = {
  groupColorPrice: Scalars['BigDecimal'];
  lease?: Maybe<LeasingInput>;
  credit?: Maybe<CreditInput>;
  deferredCredit?: Maybe<DeferredCreditInput>;
};

export enum FinanceTypeEnum {
  Leasing = 'LEASING',
  Credit = 'CREDIT',
  DeferredCredit = 'DEFERRED_CREDIT'
}

export type FuelType = CodetableDto & {
  __typename?: 'FuelType';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  state?: Maybe<CodetableStateEnum>;
};

export enum FuelTypeEnum {
  Gasoline = 'GASOLINE',
  Diesel = 'DIESEL',
  Cng = 'CNG',
  Electric = 'ELECTRIC',
  Hybrid = 'HYBRID',
  Phev = 'PHEV'
}

export type FuelTypePayload = ResponsePayload & {
  __typename?: 'FuelTypePayload';
  errors?: Maybe<Array<BusinessError>>;
  data?: Maybe<FuelType>;
};

export type GenerateAccessTokenInput = {
  refreshToken: Scalars['String'];
};

export type Image = ImageI & {
  __typename?: 'Image';
  id: Scalars['ID'];
  content: Scalars['String'];
  cardColorId: Scalars['ID'];
  fileName?: Maybe<Scalars['String']>;
  publicUrl?: Maybe<Scalars['String']>;
};

export type ImageBasic = ImageI & {
  __typename?: 'ImageBasic';
  id: Scalars['ID'];
  cardColorId?: Maybe<Scalars['ID']>;
  fileName?: Maybe<Scalars['String']>;
  publicUrl?: Maybe<Scalars['String']>;
};

export type ImageDeleteInput = {
  imageId: Scalars['ID'];
};

export type ImageDeletePayload = DeletePayload & {
  __typename?: 'ImageDeletePayload';
  errors?: Maybe<Array<BusinessError>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ImageI = {
  id: Scalars['ID'];
  cardColorId?: Maybe<Scalars['ID']>;
  fileName?: Maybe<Scalars['String']>;
  publicUrl?: Maybe<Scalars['String']>;
};

export type ImagePayload = ResponsePayload & {
  __typename?: 'ImagePayload';
  errors?: Maybe<Array<BusinessError>>;
  imageId: Scalars['ID'];
};

export type ImageUploadInput = {
  cardColorId: Scalars['ID'];
  content: Scalars['String'];
};

export type InStoreVehicleCardInput = {
  cardId: Scalars['ID'];
  cardColorId: Scalars['ID'];
  commission: Scalars['String'];
  deliveryDate: VehicleDeliveryInput;
  offerValidTo?: Maybe<Scalars['LocalDate']>;
};

export type JwtPayload = {
  __typename?: 'JwtPayload';
  jwt: Scalars['String'];
  refreshToken?: Maybe<Scalars['String']>;
};

/** Contains Language supported values in the system */
export enum LanguageEnum {
  Cs = 'CS'
}

export type Leasing = {
  __typename?: 'Leasing';
  servicePackage: ServicePackage;
  contraAccount?: Maybe<Scalars['Boolean']>;
  leasingGroups?: Maybe<Array<Maybe<LeasingGroup>>>;
};

export type LeasingGroup = {
  __typename?: 'LeasingGroup';
  type: LeasingTypeEnum;
  items?: Maybe<Array<LeasingGroupItem>>;
};

export type LeasingGroupInput = {
  type: LeasingTypeEnum;
  items?: Maybe<Array<LeasingGroupItemInput>>;
};

export type LeasingGroupItem = {
  __typename?: 'LeasingGroupItem';
  month?: Maybe<Scalars['Int']>;
  monthlyInstalment?: Maybe<Scalars['BigDecimal']>;
  totalKmPerYear?: Maybe<Scalars['Int']>;
};

export type LeasingGroupItemInput = {
  month?: Maybe<Scalars['Int']>;
  monthlyInstalment?: Maybe<Scalars['BigDecimal']>;
  totalKmPerYear?: Maybe<Scalars['Int']>;
};

export type LeasingInput = {
  serviceId: Scalars['ID'];
  contraAccount: Scalars['Boolean'];
  leasingGroups?: Maybe<Array<Maybe<LeasingGroupInput>>>;
};

export enum LeasingTypeEnum {
  Crn = 'CRN',
  PhysicalPerson = 'PHYSICAL_PERSON',
  ContraAccountCrn = 'CONTRA_ACCOUNT_CRN',
  ContraAccountPhysicalPerson = 'CONTRA_ACCOUNT_PHYSICAL_PERSON'
}



export enum LocalizationEnum {
  Cs = 'CS',
  En = 'EN'
}

export type MarketingTag = CodetableDto & {
  __typename?: 'MarketingTag';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  state?: Maybe<CodetableStateEnum>;
};

export enum MarketingTagEnum {
  Partner = 'PARTNER',
  TopOffer = 'TOP_OFFER',
  MarketLowestPrice = 'MARKET_LOWEST_PRICE',
  Reserved = 'RESERVED'
}

export type MarketingTagPayload = ResponsePayload & {
  __typename?: 'MarketingTagPayload';
  errors?: Maybe<Array<BusinessError>>;
  data?: Maybe<MarketingTag>;
};

export type ModelColor = {
  __typename?: 'ModelColor';
  id: Scalars['ID'];
  auditInfo: AuditInfo;
  name: Scalars['String'];
  color: Color;
  colorType: ColorType;
  price: Scalars['BigDecimal'];
  modelDefinitionId: Scalars['ID'];
};

export type ModelColorCreateInput = {
  name: Scalars['String'];
  colorId: Scalars['ID'];
  colorTypeId: Scalars['ID'];
  price: Scalars['BigDecimal'];
  modelDefinitionId: Scalars['ID'];
};

export type ModelColorDeleteInput = {
  id: Scalars['ID'];
};

export type ModelColorDeletePayload = DeletePayload & {
  __typename?: 'ModelColorDeletePayload';
  errors?: Maybe<Array<BusinessError>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ModelColorPayload = ResponsePayload & {
  __typename?: 'ModelColorPayload';
  errors?: Maybe<Array<BusinessError>>;
  modelColor?: Maybe<ModelColor>;
};

export type ModelColorUpdateInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  colorId: Scalars['ID'];
  colorTypeId: Scalars['ID'];
  price: Scalars['BigDecimal'];
};

export type ModelDefinition = {
  __typename?: 'ModelDefinition';
  id: Scalars['ID'];
  auditInfo: AuditInfo;
  brand: Brand;
  vehicleType: VehicleType;
  line: Scalars['String'];
  model: Scalars['String'];
  modelYear: Scalars['Int'];
  colors?: Maybe<Array<ModelColor>>;
};

export type ModelDefinitionCopyInput = {
  modelYear?: Maybe<Scalars['Int']>;
};

export type ModelDefinitionCreateInput = {
  brandId: Scalars['ID'];
  vehicleTypeCode: Scalars['String'];
  line?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  modelYear?: Maybe<Scalars['Int']>;
};

export type ModelDefinitionPayload = ResponsePayload & {
  __typename?: 'ModelDefinitionPayload';
  errors?: Maybe<Array<BusinessError>>;
  modelDefinition?: Maybe<ModelDefinition>;
};

export type ModelDefinitionUpdateInput = {
  id: Scalars['ID'];
  brandId: Scalars['ID'];
  vehicleTypeCode: Scalars['String'];
  line: Scalars['String'];
  model: Scalars['String'];
  modelYear: Scalars['Int'];
};

export type ModelEquipment = {
  __typename?: 'ModelEquipment';
  id: Scalars['ID'];
  auditInfo: AuditInfo;
  modelDefinitionId: Scalars['ID'];
  name: Scalars['String'];
  sortNumber: Scalars['Int'];
  equipments?: Maybe<Array<EquipmentItem>>;
};

export type ModelEquipmentCreateInput = {
  name: Scalars['String'];
  sortNumber: Scalars['Int'];
  modelDefinitionId: Scalars['ID'];
  modelEquipmentItems?: Maybe<Array<Maybe<ModelEquipmentItemInput>>>;
};

export type ModelEquipmentDeletePayload = DeletePayload & {
  __typename?: 'ModelEquipmentDeletePayload';
  errors?: Maybe<Array<BusinessError>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ModelEquipmentItemInput = {
  id?: Maybe<Scalars['ID']>;
  count?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  changed: Scalars['Boolean'];
  equipmentId?: Maybe<Scalars['ID']>;
  equipmentName: Scalars['String'];
  categoryId: Scalars['ID'];
  equipmentChanged: Scalars['Boolean'];
};

export type ModelEquipmentPayload = {
  __typename?: 'ModelEquipmentPayload';
  errors?: Maybe<Array<BusinessError>>;
  modelEquipment?: Maybe<ModelEquipment>;
};

export type ModelEquipmentUpdateInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  sortNumber: Scalars['Int'];
  modelEquipmentItems?: Maybe<Array<Maybe<ModelEquipmentItemInput>>>;
};

export type ModelInfo = {
  __typename?: 'ModelInfo';
  brand: Brand;
  vehicleType: VehicleType;
  line: Scalars['String'];
  model: Scalars['String'];
  modelYear: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createBody?: Maybe<BodyPayload>;
  updateBody?: Maybe<BodyPayload>;
  createBrand?: Maybe<BrandPayload>;
  updateBrand?: Maybe<BrandPayload>;
  createColor?: Maybe<ColorPayload>;
  updateColor?: Maybe<ColorPayload>;
  createColorType?: Maybe<ColorTypePayload>;
  updateColorType?: Maybe<ColorTypePayload>;
  createCountryOfOrigin?: Maybe<CountryOfOriginPayload>;
  updateCountryOfOrigin?: Maybe<CountryOfOriginPayload>;
  createMarketingTag?: Maybe<MarketingTagPayload>;
  updateMarketingTag?: Maybe<MarketingTagPayload>;
  createVehicleType?: Maybe<VehicleTypePayload>;
  updateVehicleType?: Maybe<VehicleTypePayload>;
  createTransmissionType?: Maybe<TransmissionTypePayload>;
  updateTransmissionType?: Maybe<TransmissionTypePayload>;
  createFuelType?: Maybe<FuelTypePayload>;
  updateFuelType?: Maybe<FuelTypePayload>;
  createEquipmentCategory?: Maybe<EquipmentCategoryPayload>;
  updateEquipmentCategory?: Maybe<EquipmentCategoryPayload>;
  createWheelbaseType?: Maybe<WheelbaseTypePayload>;
  updateWheelbaseType?: Maybe<WheelbaseTypePayload>;
  createBonus?: Maybe<BonusPayload>;
  updateBonus?: Maybe<BonusPayload>;
  createServicePackage?: Maybe<ServicePackagePayload>;
  updateServicePackage?: Maybe<ServicePackagePayload>;
  updateServicePackageState?: Maybe<ServicePackagePayload>;
  createEquipment?: Maybe<EquipmentPayload>;
  updateEquipment?: Maybe<EquipmentPayload>;
  createModelDefinition?: Maybe<ModelDefinitionPayload>;
  updateModelDefinition?: Maybe<ModelDefinitionPayload>;
  createModelColor?: Maybe<ModelColorPayload>;
  updateModelColor?: Maybe<ModelColorPayload>;
  deleteModelColor?: Maybe<ModelColorDeletePayload>;
  createModelEquipment?: Maybe<ModelEquipmentPayload>;
  updateModelEquipment?: Maybe<ModelEquipmentPayload>;
  createModelEquipmentItem?: Maybe<EquipmentItemPayload>;
  updateModelEquipmentItem?: Maybe<EquipmentItemPayload>;
  deleteModelEquipmentItem?: Maybe<ModelEquipmentDeletePayload>;
  createVehicleDefinition?: Maybe<VehicleDefinition>;
  updateVehicleDefinition?: Maybe<VehicleDefinitionPayload>;
  updateArchivedStateVehicleDefinition?: Maybe<VehicleDefinitionPayload>;
  duplicateVehicleDefinition?: Maybe<VehicleDefinitionPayload>;
  copyVehicleDefinition?: Maybe<VehicleDefinitionPayload>;
  createTechnicalSpec?: Maybe<TechnicalSpecificationPayload>;
  updateTechnicalSpec?: Maybe<TechnicalSpecificationPayload>;
  createVehicleCard?: Maybe<VehicleCardPayload>;
  updateVehicleCard?: Maybe<VehicleCardPayload>;
  updateVehicleCardState?: Maybe<VehicleCardPayload>;
  createInStoreVehicleCard?: Maybe<VehicleCardPayload>;
  createVehicleCardColor?: Maybe<VehicleCardColorPayload>;
  updateVehicleCardColor?: Maybe<VehicleCardColorPayload>;
  deleteVehicleCardColor?: Maybe<CardColorDeletePayload>;
  createVehicleCardExtraEquipment?: Maybe<VehicleCardExtraEquipmentPayload>;
  updateVehicleCardExtraEquipment?: Maybe<VehicleCardExtraEquipmentPayload>;
  createVehicleCardFinance?: Maybe<VehicleCardFinancePayload>;
  updateVehicleCardFinance?: Maybe<VehicleCardFinancePayload>;
  uploadImage?: Maybe<ImagePayload>;
  deleteImage?: Maybe<ImageDeletePayload>;
  createVehicleCardMarketing?: Maybe<VehicleCardMarketingPayload>;
  updateVehicleCardMarketing?: Maybe<VehicleCardMarketingPayload>;
  changePassword: JwtPayload;
  generateAccessToken: JwtPayload;
  createUsedVehicleCard?: Maybe<UsedVehicleCard>;
  updateUsedVehicleCard?: Maybe<UsedVehicleCardPayload>;
  uploadUsedVehicleImage?: Maybe<UsedVehicleImagePayload>;
  deleteUsedVehicleImage?: Maybe<UsedVehicleImageDeletePayload>;
  updateUsedVehicleCardState?: Maybe<UsedVehicleCardPayload>;
};


export type MutationCreateBodyArgs = {
  input: BodyCreateDto;
};


export type MutationUpdateBodyArgs = {
  input: BodyUpdateDto;
};


export type MutationCreateBrandArgs = {
  input: CodetableCreateDto;
};


export type MutationUpdateBrandArgs = {
  input: CodetableUpdateDto;
};


export type MutationCreateColorArgs = {
  input: CodetableCreateDto;
};


export type MutationUpdateColorArgs = {
  input: CodetableUpdateDto;
};


export type MutationCreateColorTypeArgs = {
  input: CodetableCreateDto;
};


export type MutationUpdateColorTypeArgs = {
  input: CodetableUpdateDto;
};


export type MutationCreateCountryOfOriginArgs = {
  input: CodetableCreateDto;
};


export type MutationUpdateCountryOfOriginArgs = {
  input: CodetableUpdateDto;
};


export type MutationCreateMarketingTagArgs = {
  input: CodetableCreateDto;
};


export type MutationUpdateMarketingTagArgs = {
  input: CodetableUpdateDto;
};


export type MutationCreateVehicleTypeArgs = {
  input: CodetableCreateDto;
};


export type MutationUpdateVehicleTypeArgs = {
  input: CodetableUpdateDto;
};


export type MutationCreateTransmissionTypeArgs = {
  input: CodetableCreateDto;
};


export type MutationUpdateTransmissionTypeArgs = {
  input: CodetableUpdateDto;
};


export type MutationCreateFuelTypeArgs = {
  input: CodetableCreateDto;
};


export type MutationUpdateFuelTypeArgs = {
  input: CodetableUpdateDto;
};


export type MutationCreateEquipmentCategoryArgs = {
  input: CodetableCreateDto;
};


export type MutationUpdateEquipmentCategoryArgs = {
  input: CodetableUpdateDto;
};


export type MutationCreateWheelbaseTypeArgs = {
  input: CodetableCreateDto;
};


export type MutationUpdateWheelbaseTypeArgs = {
  input: CodetableUpdateDto;
};


export type MutationCreateBonusArgs = {
  input: BonusCreateInput;
};


export type MutationUpdateBonusArgs = {
  input: BonusUpdateInput;
};


export type MutationCreateServicePackageArgs = {
  input: ServicePackageCreateInput;
};


export type MutationUpdateServicePackageArgs = {
  input: ServicePackageUpdateInput;
};


export type MutationUpdateServicePackageStateArgs = {
  input: ServicePackageStateUpdateInput;
};


export type MutationCreateEquipmentArgs = {
  input: EquipmentCreateInput;
};


export type MutationUpdateEquipmentArgs = {
  input: EquipmentUpdateInput;
};


export type MutationCreateModelDefinitionArgs = {
  input: ModelDefinitionCreateInput;
};


export type MutationUpdateModelDefinitionArgs = {
  input: ModelDefinitionUpdateInput;
};


export type MutationCreateModelColorArgs = {
  input: ModelColorCreateInput;
};


export type MutationUpdateModelColorArgs = {
  input: ModelColorUpdateInput;
};


export type MutationDeleteModelColorArgs = {
  input: ModelColorDeleteInput;
};


export type MutationCreateModelEquipmentArgs = {
  input: ModelEquipmentCreateInput;
};


export type MutationUpdateModelEquipmentArgs = {
  input: ModelEquipmentUpdateInput;
};


export type MutationCreateModelEquipmentItemArgs = {
  input: EquipmentItemCreateInput;
};


export type MutationUpdateModelEquipmentItemArgs = {
  input: EquipmentItemUpdateInput;
};


export type MutationDeleteModelEquipmentItemArgs = {
  input: EquipmentItemDeleteInput;
};


export type MutationUpdateVehicleDefinitionArgs = {
  input: VehicleDefinitionUpdateInput;
};


export type MutationUpdateArchivedStateVehicleDefinitionArgs = {
  input: VehicleDefinitionArchivedStateUpdateInput;
};


export type MutationDuplicateVehicleDefinitionArgs = {
  input: VehicleDefinitionDuplicateInput;
};


export type MutationCopyVehicleDefinitionArgs = {
  input: VehicleDefinitionCopyInput;
};


export type MutationCreateTechnicalSpecArgs = {
  input: TechnicalSpecificationCreateInput;
};


export type MutationUpdateTechnicalSpecArgs = {
  input: TechnicalSpecificationUpdateInput;
};


export type MutationCreateVehicleCardArgs = {
  input: VehicleCardCreateInput;
};


export type MutationUpdateVehicleCardArgs = {
  input: VehicleCardUpdateInput;
};


export type MutationUpdateVehicleCardStateArgs = {
  input: VehicleCardStateUpdateInput;
};


export type MutationCreateInStoreVehicleCardArgs = {
  input: InStoreVehicleCardInput;
};


export type MutationCreateVehicleCardColorArgs = {
  input: VehicleCardColorCreateInput;
};


export type MutationUpdateVehicleCardColorArgs = {
  input: VehicleCardColorUpdateInput;
};


export type MutationDeleteVehicleCardColorArgs = {
  input: VehicleCardColorDeleteInput;
};


export type MutationCreateVehicleCardExtraEquipmentArgs = {
  input: VehicleCardExtraEquipmentCreateInput;
};


export type MutationUpdateVehicleCardExtraEquipmentArgs = {
  input: VehicleCardExtraEquipmentUpdateInput;
};


export type MutationCreateVehicleCardFinanceArgs = {
  input: VehicleCardFinanceCreateInput;
};


export type MutationUpdateVehicleCardFinanceArgs = {
  input: VehicleCardFinanceUpdateInput;
};


export type MutationUploadImageArgs = {
  input: ImageUploadInput;
};


export type MutationDeleteImageArgs = {
  input: ImageDeleteInput;
};


export type MutationCreateVehicleCardMarketingArgs = {
  input: VehicleCardMarketingCreateInput;
};


export type MutationUpdateVehicleCardMarketingArgs = {
  input: VehicleCardMarketingUpdateInput;
};


export type MutationChangePasswordArgs = {
  input?: Maybe<ChangePasswordInput>;
};


export type MutationGenerateAccessTokenArgs = {
  input?: Maybe<GenerateAccessTokenInput>;
};


export type MutationCreateUsedVehicleCardArgs = {
  input: UsedVehicleCardInput;
};


export type MutationUpdateUsedVehicleCardArgs = {
  input: UsedVehicleCardUpdateInput;
};


export type MutationUploadUsedVehicleImageArgs = {
  input: UsedVehicleImageUploadInput;
};


export type MutationDeleteUsedVehicleImageArgs = {
  input: UsedVehicleImageDeleteInput;
};


export type MutationUpdateUsedVehicleCardStateArgs = {
  input: UsedVehicleCardStateUpdateInput;
};

export type NvDefinitionDistinctModelInfoPage = {
  __typename?: 'NVDefinitionDistinctModelInfoPage';
  data: Array<ModelInfo>;
};

export type NewVehicleCardFeedFilter = {
  updated?: Maybe<DateTimeFilter>;
  brandIds?: Maybe<Array<Scalars['ID']>>;
  lines?: Maybe<Array<Scalars['String']>>;
  models?: Maybe<Array<Scalars['String']>>;
  modelYears?: Maybe<Array<Scalars['Int']>>;
  fuelTypeIds?: Maybe<Array<Scalars['ID']>>;
  bodyIds?: Maybe<Array<Scalars['ID']>>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
};

export type PriceFilter = {
  from?: Maybe<Scalars['BigDecimal']>;
  to?: Maybe<Scalars['BigDecimal']>;
};

export type Query = {
  __typename?: 'Query';
  getBrands: Array<Brand>;
  getBrand: Brand;
  getVehicleTypes: Array<VehicleType>;
  getVehicleType: VehicleType;
  getColors: Array<Color>;
  getColor: Color;
  getColorTypes: Array<ColorType>;
  getColorType: ColorType;
  getBodies: Array<Body>;
  getBody: Body;
  getFuelTypes: Array<FuelType>;
  getFuelType: FuelType;
  getTransmissionTypes: Array<TransmissionType>;
  getTransmissionType: TransmissionType;
  getWheelbaseTypes: Array<WheelbaseType>;
  getWheelbaseType: WheelbaseType;
  getEquipmentCategories: Array<EquipmentCategory>;
  getEquipmentCategory: EquipmentCategory;
  getMarketingTags: Array<MarketingTag>;
  getMarketingTag: MarketingTag;
  getCountriesOfOrigin: Array<CountryOfOrigin>;
  getCountryOfOrigin: CountryOfOrigin;
  getEquipments: Array<Equipment>;
  getServicePackages: Array<ServicePackage>;
  getServicePackage: ServicePackage;
  getBonuses: Array<Bonus>;
  getModelDefinitions: Array<ModelDefinition>;
  getModelDefinitionBrands: Array<Brand>;
  getModelDefinitionLines: Array<Scalars['String']>;
  getModelDefinitionModels: Array<Scalars['String']>;
  getModelDefinitionModelYears: Array<Scalars['Int']>;
  getModelEquipments: Array<ModelEquipment>;
  getModelEquipmentNames: Array<Scalars['String']>;
  /**
   * Get distinct list of Model Info using Model Definition
   *    - Brand
   *    - Model
   *    - Line
   *    - ModeYear
   *    - VehicleType
   */
  getNVDefinitionDistinctModelInfos: NvDefinitionDistinctModelInfoPage;
  getVehicleDefinitions: VehicleDefinitionPage;
  getVehicleDefinition?: Maybe<VehicleDefinition>;
  getEngines?: Maybe<Array<Scalars['String']>>;
  getVehicleCards: VehicleCardPage;
  getVehicleCard?: Maybe<VehicleCard>;
  getImage?: Maybe<Image>;
  getVehicleCardsFeed: VehicleCardPage;
  getUsedVehicleCards: UsedVehicleCardPage;
  getUsedVehicleCard: UsedVehicleCard;
  /**
   * This query returns UsedVehicleCards with status APPROVED or PUBLISHED.
   * Maximal limit value is set to 1000 entries.
   */
  getUsedVehicleFeedCards: UsedVehicleFeedCardPage;
  /** This query returns partial data (brand, model, modelYear, line, vehicleType) of Used Vehicle Definition Model with Used Vehicle Card status APPROVED or PUBLISHED. */
  getUVDefinitionDistinctModelInfos: UvDefinitionDistinctModelInfoPage;
};


export type QueryGetBrandsArgs = {
  states?: Maybe<Array<CodetableStateEnum>>;
};


export type QueryGetBrandArgs = {
  id: Scalars['ID'];
};


export type QueryGetVehicleTypesArgs = {
  states?: Maybe<Array<CodetableStateEnum>>;
};


export type QueryGetVehicleTypeArgs = {
  id: Scalars['ID'];
};


export type QueryGetColorsArgs = {
  states?: Maybe<Array<CodetableStateEnum>>;
};


export type QueryGetColorArgs = {
  id: Scalars['ID'];
};


export type QueryGetColorTypesArgs = {
  states?: Maybe<Array<CodetableStateEnum>>;
};


export type QueryGetColorTypeArgs = {
  id: Scalars['ID'];
};


export type QueryGetBodiesArgs = {
  states?: Maybe<Array<CodetableStateEnum>>;
};


export type QueryGetBodyArgs = {
  id: Scalars['ID'];
};


export type QueryGetFuelTypesArgs = {
  states?: Maybe<Array<CodetableStateEnum>>;
};


export type QueryGetFuelTypeArgs = {
  id: Scalars['ID'];
};


export type QueryGetTransmissionTypesArgs = {
  states?: Maybe<Array<CodetableStateEnum>>;
};


export type QueryGetTransmissionTypeArgs = {
  id: Scalars['ID'];
};


export type QueryGetWheelbaseTypesArgs = {
  states?: Maybe<Array<CodetableStateEnum>>;
};


export type QueryGetWheelbaseTypeArgs = {
  id: Scalars['ID'];
};


export type QueryGetEquipmentCategoriesArgs = {
  states?: Maybe<Array<CodetableStateEnum>>;
};


export type QueryGetEquipmentCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryGetMarketingTagsArgs = {
  states?: Maybe<Array<CodetableStateEnum>>;
};


export type QueryGetMarketingTagArgs = {
  id: Scalars['ID'];
};


export type QueryGetCountriesOfOriginArgs = {
  states?: Maybe<Array<CodetableStateEnum>>;
};


export type QueryGetCountryOfOriginArgs = {
  id: Scalars['ID'];
};


export type QueryGetServicePackagesArgs = {
  filter?: Maybe<ServicePackageFilter>;
};


export type QueryGetServicePackageArgs = {
  id: Scalars['ID'];
};


export type QueryGetBonusesArgs = {
  filter?: Maybe<BonusFilter>;
};


export type QueryGetModelEquipmentsArgs = {
  modelDefinitionId?: Maybe<Scalars['ID']>;
};


export type QueryGetModelEquipmentNamesArgs = {
  modelDefinitionId?: Maybe<Scalars['ID']>;
};


export type QueryGetVehicleDefinitionsArgs = {
  filter?: Maybe<VehicleDefinitionFilter>;
  sort?: Maybe<Array<SortBy>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryGetVehicleDefinitionArgs = {
  id: Scalars['ID'];
};


export type QueryGetVehicleCardsArgs = {
  filter?: Maybe<VehicleCardFilter>;
  sort?: Maybe<Array<SortBy>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryGetVehicleCardArgs = {
  id: Scalars['ID'];
};


export type QueryGetImageArgs = {
  id: Scalars['ID'];
};


export type QueryGetVehicleCardsFeedArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<NewVehicleCardFeedFilter>;
};


export type QueryGetUsedVehicleCardsArgs = {
  filter?: Maybe<UsedVehicleCardFilter>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryGetUsedVehicleCardArgs = {
  id: Scalars['ID'];
};


export type QueryGetUsedVehicleFeedCardsArgs = {
  filter?: Maybe<UsedVehicleFeedCardFilter>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type ResponsePayload = {
  errors?: Maybe<Array<BusinessError>>;
};

export type ServicePackage = {
  __typename?: 'ServicePackage';
  id: Scalars['ID'];
  auditInfo?: Maybe<AuditInfo>;
  name?: Maybe<Scalars['String']>;
  services: Array<Scalars['String']>;
  state: ServicePackageStateEnum;
};

export type ServicePackageCreateInput = {
  name?: Maybe<Scalars['String']>;
  items: Array<Scalars['String']>;
};

export type ServicePackageFilter = {
  states?: Maybe<Array<ServicePackageStateEnum>>;
};

export type ServicePackagePayload = ResponsePayload & {
  __typename?: 'ServicePackagePayload';
  errors?: Maybe<Array<BusinessError>>;
  servicePackage?: Maybe<ServicePackage>;
};

export enum ServicePackageStateEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED'
}

export type ServicePackageStateUpdateInput = {
  id: Scalars['ID'];
  state: ServicePackageStateEnum;
};

export type ServicePackageUpdateInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  items: Array<Scalars['String']>;
};

export type SortBy = {
  key: Scalars['String'];
  sort: SortEnum;
};

export enum SortEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type TechnicalSpecification = {
  __typename?: 'TechnicalSpecification';
  id: Scalars['ID'];
  auditInfo: AuditInfo;
  modelKey: Scalars['String'];
  modelKeyExtension?: Maybe<Scalars['String']>;
  optionCode: Scalars['String'];
  price: Scalars['BigDecimal'];
  body: Body;
  engine: Scalars['String'];
  fuelType: FuelType;
  transmission: Scalars['String'];
  transmissionType: TransmissionType;
  power: Scalars['String'];
  emission: Scalars['Float'];
  cubicCapacity: Scalars['Float'];
  fuelConsumption: Scalars['Float'];
  seats: Scalars['Int'];
  driveWheel: Scalars['String'];
  fourDriveWheel: Scalars['Boolean'];
  range?: Maybe<Scalars['Int']>;
  trunkSpace?: Maybe<Scalars['Float']>;
  trunkLength?: Maybe<Scalars['Float']>;
  numberOfPallets?: Maybe<Scalars['Int']>;
  wheelbase?: Maybe<WheelbaseType>;
};

export type TechnicalSpecificationCreateInput = {
  modelEquipmentId: Scalars['ID'];
  modelKey: Scalars['String'];
  modelKeyExtension?: Maybe<Scalars['String']>;
  optionCode: Scalars['String'];
  price: Scalars['BigDecimal'];
  body: Scalars['ID'];
  engine: Scalars['String'];
  fuelType: Scalars['ID'];
  transmission: Scalars['String'];
  transmissionType: Scalars['ID'];
  power: Scalars['String'];
  emission: Scalars['Float'];
  cubicCapacity: Scalars['Float'];
  fuelConsumption: Scalars['Float'];
  seats: Scalars['Int'];
  driveWheel: Scalars['String'];
  fourDriveWheel: Scalars['Boolean'];
  range?: Maybe<Scalars['Int']>;
  trunkSpace?: Maybe<Scalars['Float']>;
  trunkLength?: Maybe<Scalars['Float']>;
  numberOfPallets?: Maybe<Scalars['Int']>;
  wheelbase?: Maybe<Scalars['ID']>;
};

export type TechnicalSpecificationPayload = ResponsePayload & {
  __typename?: 'TechnicalSpecificationPayload';
  errors?: Maybe<Array<BusinessError>>;
  technicalSpecification?: Maybe<TechnicalSpecification>;
};

export type TechnicalSpecificationUpdateInput = {
  id: Scalars['ID'];
  modelKey: Scalars['String'];
  modelKeyExtension?: Maybe<Scalars['String']>;
  optionCode: Scalars['String'];
  price: Scalars['BigDecimal'];
  body: Scalars['ID'];
  engine: Scalars['String'];
  fuelType: Scalars['ID'];
  transmission: Scalars['String'];
  transmissionType: Scalars['ID'];
  power: Scalars['String'];
  emission: Scalars['Float'];
  cubicCapacity: Scalars['Float'];
  fuelConsumption: Scalars['Float'];
  seats: Scalars['Int'];
  driveWheel: Scalars['String'];
  fourDriveWheel: Scalars['Boolean'];
  range?: Maybe<Scalars['Int']>;
  trunkSpace?: Maybe<Scalars['Float']>;
  trunkLength?: Maybe<Scalars['Float']>;
  numberOfPallets?: Maybe<Scalars['Int']>;
  wheelbase?: Maybe<Scalars['ID']>;
};

export type TransmissionType = CodetableDto & {
  __typename?: 'TransmissionType';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  state?: Maybe<CodetableStateEnum>;
};

export type TransmissionTypePayload = ResponsePayload & {
  __typename?: 'TransmissionTypePayload';
  errors?: Maybe<Array<BusinessError>>;
  data?: Maybe<TransmissionType>;
};

export type UvDefinitionDistinctModelInfoPage = {
  __typename?: 'UVDefinitionDistinctModelInfoPage';
  data: Array<ModelInfo>;
};

export type UsedVehicleCard = {
  __typename?: 'UsedVehicleCard';
  id: Scalars['ID'];
  createdBy: Scalars['String'];
  created: Scalars['LocalDateTime'];
  updatedBy?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['LocalDateTime']>;
  state: UsedVehicleCardStateEnum;
  extraEquipment?: Maybe<UsedVehicleCardExtraEquipment>;
  vehicleDefinition: UsedVehicleDefinition;
  finance?: Maybe<UsedVehicleCardFinance>;
  marketing?: Maybe<UsedVehicleCardMarketing>;
  images?: Maybe<Array<UsedVehicleImage>>;
  externalSourceSystem?: Maybe<ExternalSystem>;
  publishedExternalSystem?: Maybe<Array<ExternalSystem>>;
  seller?: Maybe<UsedVehicleSeller>;
};

export type UsedVehicleCardCreateInput = {
  card?: Maybe<UsedVehicleCardInput>;
};

export type UsedVehicleCardExtraEquipment = {
  __typename?: 'UsedVehicleCardExtraEquipment';
  id: Scalars['ID'];
  items?: Maybe<Array<ExtraEquipmentItem>>;
};

export type UsedVehicleCardExtraEquipmentPayload = ResponsePayload & {
  __typename?: 'UsedVehicleCardExtraEquipmentPayload';
  errors?: Maybe<Array<BusinessError>>;
  extraEquipment?: Maybe<UsedVehicleCardExtraEquipment>;
};

export type UsedVehicleCardExtraEquipmentUpdateInput = {
  extraEquipmentId: Scalars['ID'];
  items?: Maybe<Array<ExtraEquipmentItemInput>>;
};

export type UsedVehicleCardFilter = {
  vin?: Maybe<Scalars['String']>;
  brand?: Maybe<Array<Scalars['ID']>>;
  state?: Maybe<Array<UsedVehicleCardStateEnum>>;
};

export type UsedVehicleCardFinance = {
  __typename?: 'UsedVehicleCardFinance';
  id: Scalars['ID'];
  bonuses?: Maybe<Array<Bonus>>;
  autoCmsDiscountCRN?: Maybe<Scalars['BigDecimal']>;
  autoCmsDiscountPP?: Maybe<Scalars['BigDecimal']>;
  coinsurance?: Maybe<Scalars['Float']>;
  lease?: Maybe<Leasing>;
  credit?: Maybe<Credit>;
  deferredCredit?: Maybe<DeferredCredit>;
};

export type UsedVehicleCardFinanceInput = {
  bonusIds?: Maybe<Array<Scalars['ID']>>;
  autoCmsDiscountCRN?: Maybe<Scalars['BigDecimal']>;
  autoCmsDiscountPP?: Maybe<Scalars['BigDecimal']>;
  coinsurance?: Maybe<Scalars['Float']>;
  lease?: Maybe<LeasingInput>;
  credit?: Maybe<CreditInput>;
  deferredCredit?: Maybe<DeferredCreditInput>;
};

export type UsedVehicleCardInput = {
  state: UsedVehicleCardStateEnum;
  extraEquipment?: Maybe<Array<ExtraEquipmentItemInput>>;
  vehicleDefinition: UsedVehicleDefinitionInput;
  finance?: Maybe<UsedVehicleCardFinanceInput>;
  marketing?: Maybe<UsedVehicleCardMarketingInput>;
  images?: Maybe<Array<UsedVehicleImageInput>>;
  externalSourceSystem?: Maybe<ExternalSystemInput>;
  publishedExternalSystem?: Maybe<Array<ExternalSystemInput>>;
  seller?: Maybe<UsedVehicleSellerInput>;
};

export type UsedVehicleCardMarketing = {
  __typename?: 'UsedVehicleCardMarketing';
  id: Scalars['ID'];
  marketingTags?: Maybe<Array<MarketingTag>>;
};

export type UsedVehicleCardMarketingInput = {
  marketingTags?: Maybe<Array<Scalars['ID']>>;
};

export type UsedVehicleCardPage = {
  __typename?: 'UsedVehicleCardPage';
  data: Array<UsedVehicleCard>;
  pageInfo: PageInfo;
};

export type UsedVehicleCardPayload = ResponsePayload & {
  __typename?: 'UsedVehicleCardPayload';
  errors?: Maybe<Array<BusinessError>>;
  usedVehicleCard?: Maybe<UsedVehicleCard>;
};

export enum UsedVehicleCardStateEnum {
  InProgress = 'IN_PROGRESS',
  AwaitApproval = 'AWAIT_APPROVAL',
  Approved = 'APPROVED',
  Published = 'PUBLISHED',
  Sold = 'SOLD',
  Archived = 'ARCHIVED',
  Unarchive = 'UNARCHIVE'
}

export type UsedVehicleCardStateUpdateInput = {
  cardId: Scalars['ID'];
  state: UsedVehicleCardStateEnum;
};

export type UsedVehicleCardUpdateInput = {
  id: Scalars['ID'];
  card?: Maybe<UsedVehicleCardInput>;
};

export type UsedVehicleColor = {
  __typename?: 'UsedVehicleColor';
  id: Scalars['ID'];
  name: Scalars['String'];
  color: Color;
  colorType: ColorType;
};

export type UsedVehicleColorInput = {
  name: Scalars['String'];
  color: Scalars['ID'];
  colorType: Scalars['ID'];
};

export type UsedVehicleDefinition = {
  __typename?: 'UsedVehicleDefinition';
  id: Scalars['ID'];
  vin: Scalars['String'];
  createdBy: Scalars['String'];
  created: Scalars['LocalDateTime'];
  updatedBy?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['LocalDateTime']>;
  brand: Brand;
  vehicleType: VehicleType;
  line: Scalars['String'];
  model: Scalars['String'];
  modelYear: Scalars['Int'];
  color?: Maybe<UsedVehicleColor>;
  equipment?: Maybe<UsedVehicleEquipment>;
  modelKey: Scalars['String'];
  modelKeyExtension?: Maybe<Scalars['String']>;
  price: Scalars['BigDecimal'];
  currency: CurrencyEnum;
  body: Body;
  engine: Scalars['String'];
  fuelType: FuelType;
  transmission: Scalars['String'];
  transmissionType: TransmissionType;
  power: Scalars['String'];
  emission: Scalars['Float'];
  cubicCapacity: Scalars['Float'];
  fuelConsumption: Scalars['Float'];
  seats: Scalars['Int'];
  driveWheel: Scalars['String'];
  fourDriveWheel: Scalars['Boolean'];
  range?: Maybe<Scalars['Int']>;
  trunkSpace?: Maybe<Scalars['Float']>;
  trunkLength?: Maybe<Scalars['Float']>;
  numberOfPallets?: Maybe<Scalars['Int']>;
  wheelbase?: Maybe<WheelbaseType>;
  mileage: Scalars['Int'];
  cubicCapacityDescription?: Maybe<Scalars['String']>;
  countryOfOrigin?: Maybe<CountryOfOrigin>;
  intoOperation?: Maybe<Scalars['Date']>;
  stkUntil?: Maybe<Scalars['Date']>;
  emissionUntil?: Maybe<Scalars['Date']>;
  doorsNumber?: Maybe<Scalars['Int']>;
  shifts?: Maybe<Scalars['Int']>;
  serviceBookFlag?: Maybe<Scalars['Boolean']>;
  noCrashFlag?: Maybe<Scalars['Boolean']>;
  vatExpelFlag?: Maybe<Scalars['Boolean']>;
  auctionPrice?: Maybe<Scalars['Float']>;
  newVehiclePrice?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
  vehicleState?: Maybe<Scalars['String']>;
  ownerCount: Scalars['Int'];
  refVehicle?: Maybe<Scalars['Boolean']>;
  customAttributes?: Maybe<Array<CustomAttribute>>;
};

export type UsedVehicleDefinitionInput = {
  vin: Scalars['String'];
  brandId: Scalars['ID'];
  vehicleType: Scalars['ID'];
  line: Scalars['String'];
  model: Scalars['String'];
  modelYear: Scalars['Int'];
  color?: Maybe<UsedVehicleColorInput>;
  equipment?: Maybe<UsedVehicleEquipmentInput>;
  modelKey: Scalars['String'];
  modelKeyExtension?: Maybe<Scalars['String']>;
  price: Scalars['BigDecimal'];
  currency: CurrencyEnum;
  body: Scalars['ID'];
  engine: Scalars['String'];
  fuelType: Scalars['ID'];
  transmission: Scalars['String'];
  transmissionType: Scalars['ID'];
  power: Scalars['String'];
  emission: Scalars['Float'];
  cubicCapacity: Scalars['Float'];
  fuelConsumption: Scalars['Float'];
  seats: Scalars['Int'];
  driveWheel: Scalars['String'];
  fourDriveWheel: Scalars['Boolean'];
  range?: Maybe<Scalars['Int']>;
  trunkSpace?: Maybe<Scalars['Float']>;
  trunkLength?: Maybe<Scalars['Float']>;
  numberOfPallets?: Maybe<Scalars['Int']>;
  wheelbase?: Maybe<Scalars['ID']>;
  mileage: Scalars['Int'];
  cubicCapacityDescription?: Maybe<Scalars['String']>;
  countryOfOrigin?: Maybe<Scalars['ID']>;
  intoOperation?: Maybe<Scalars['Date']>;
  stkUntil?: Maybe<Scalars['Date']>;
  emissionUntil?: Maybe<Scalars['Date']>;
  doorsNumber?: Maybe<Scalars['Int']>;
  shifts?: Maybe<Scalars['Int']>;
  serviceBookFlag?: Maybe<Scalars['Boolean']>;
  noCrashFlag?: Maybe<Scalars['Boolean']>;
  vatExpelFlag?: Maybe<Scalars['Boolean']>;
  auctionPrice?: Maybe<Scalars['Float']>;
  newVehiclePrice?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
  vehicleState?: Maybe<Scalars['String']>;
  ownerCount: Scalars['Int'];
  refVehicle?: Maybe<Scalars['Boolean']>;
  customAttributes?: Maybe<Array<CustomAttributeInput>>;
};

export type UsedVehicleEquipment = {
  __typename?: 'UsedVehicleEquipment';
  id: Scalars['ID'];
  name: Scalars['String'];
  equipments?: Maybe<Array<UsedVehicleEquipmentItem>>;
};

export type UsedVehicleEquipmentInput = {
  name: Scalars['String'];
  equipments?: Maybe<Array<UsedVehicleEquipmentItemInput>>;
};

export type UsedVehicleEquipmentItem = {
  __typename?: 'UsedVehicleEquipmentItem';
  equipment: Equipment;
  count?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};

export type UsedVehicleEquipmentItemInput = {
  equipmentId: Scalars['ID'];
  count?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};

export type UsedVehicleFeedCard = {
  __typename?: 'UsedVehicleFeedCard';
  id: Scalars['ID'];
  created: Scalars['LocalDateTime'];
  updated?: Maybe<Scalars['LocalDateTime']>;
  extraEquipment?: Maybe<UsedVehicleCardExtraEquipment>;
  vehicleDefinition: UsedVehicleDefinition;
  finance?: Maybe<UsedVehicleCardFinance>;
  marketing?: Maybe<UsedVehicleCardMarketing>;
  images?: Maybe<Array<UsedVehicleImage>>;
  seller?: Maybe<UsedVehicleSeller>;
};

export type UsedVehicleFeedCardFilter = {
  /** Filters result data using attribute UsedVehicleCard#updated from passed date-time. Expects ISO 8601 format. */
  updatedFrom?: Maybe<Scalars['LocalDateTime']>;
  /** Filters result data using attribute UsedVehicleCard#updated to passed date-time. Expects ISO 8601 format. */
  updatedTo?: Maybe<Scalars['LocalDateTime']>;
  brandIds?: Maybe<Array<Scalars['ID']>>;
  lines?: Maybe<Array<Scalars['String']>>;
  models?: Maybe<Array<Scalars['String']>>;
  modelYears?: Maybe<Array<Scalars['Int']>>;
  fuelTypeIds?: Maybe<Array<Scalars['ID']>>;
  bodyIds?: Maybe<Array<Scalars['ID']>>;
  vehicleStates?: Maybe<Array<Scalars['String']>>;
};

export type UsedVehicleFeedCardPage = {
  __typename?: 'UsedVehicleFeedCardPage';
  data: Array<UsedVehicleFeedCard>;
  pageInfo: PageInfo;
};

export type UsedVehicleImage = {
  __typename?: 'UsedVehicleImage';
  id: Scalars['ID'];
  fileName: Scalars['String'];
  publicUrl: Scalars['String'];
  mainImage?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
};

export type UsedVehicleImageDeleteInput = {
  /**
   * fileName consists of image ID and original image extension
   * eg.: 349d5bda-0741-4b9c-b9d3-8476818b86ad.png
   */
  imageId: Scalars['ID'];
  fileName: Scalars['String'];
};

export type UsedVehicleImageDeletePayload = DeletePayload & {
  __typename?: 'UsedVehicleImageDeletePayload';
  errors?: Maybe<Array<BusinessError>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UsedVehicleImageInput = {
  id: Scalars['ID'];
  fileName: Scalars['String'];
  publicUrl: Scalars['String'];
  mainImage?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
};

export type UsedVehicleImagePayload = ResponsePayload & {
  __typename?: 'UsedVehicleImagePayload';
  errors?: Maybe<Array<BusinessError>>;
  imageId: Scalars['ID'];
  publicUrl: Scalars['String'];
  fileName: Scalars['String'];
};

export type UsedVehicleImageUploadInput = {
  content: Scalars['String'];
};

export type UsedVehicleMainImageUpdateInput = {
  imageId: Scalars['ID'];
  cardId: Scalars['ID'];
};

export type UsedVehicleMainImageUpdatePayload = DeletePayload & {
  __typename?: 'UsedVehicleMainImageUpdatePayload';
  errors?: Maybe<Array<BusinessError>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UsedVehicleSeller = {
  __typename?: 'UsedVehicleSeller';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type UsedVehicleSellerInput = {
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type VehicleCard = {
  __typename?: 'VehicleCard';
  id: Scalars['ID'];
  auditInfo: AuditInfo;
  state: VehicleCardStateEnum;
  commission: Scalars['String'];
  inStoreCard: Scalars['Boolean'];
  offerValidTo?: Maybe<Scalars['LocalDate']>;
  deliveryDate?: Maybe<VehicleDelivery>;
  definition?: Maybe<VehicleDefinition>;
  colors?: Maybe<Array<VehicleCardColor>>;
  extraEquipment?: Maybe<VehicleCardExtraEquipment>;
  finance?: Maybe<VehicleCardFinance>;
  marketing?: Maybe<VehicleCardMarketing>;
  sourceCardId?: Maybe<Scalars['ID']>;
};

export type VehicleCardColor = {
  __typename?: 'VehicleCardColor';
  id: Scalars['ID'];
  commissionColorId: Scalars['String'];
  modelColor: ModelColor;
  mainImageId?: Maybe<Scalars['ID']>;
  images?: Maybe<Array<ImageBasic>>;
};

export type VehicleCardColorCreateInput = {
  cardId: Scalars['ID'];
  modelColorId: Scalars['ID'];
};

export type VehicleCardColorDeleteInput = {
  cardColorId: Scalars['ID'];
};

export type VehicleCardColorPayload = ResponsePayload & {
  __typename?: 'VehicleCardColorPayload';
  errors?: Maybe<Array<BusinessError>>;
  cardColor?: Maybe<VehicleCardColor>;
};

export type VehicleCardColorUpdateInput = {
  cardColorId: Scalars['ID'];
  mainImageId?: Maybe<Scalars['ID']>;
};

export type VehicleCardCreateInput = {
  commission: Scalars['String'];
  offerValidTo?: Maybe<Scalars['LocalDate']>;
  deliveryDate?: Maybe<VehicleDeliveryInput>;
  definitionId: Scalars['ID'];
};

export type VehicleCardExtraEquipment = {
  __typename?: 'VehicleCardExtraEquipment';
  id: Scalars['ID'];
  items?: Maybe<Array<ExtraEquipmentItem>>;
};

export type VehicleCardExtraEquipmentCreateInput = {
  cardId: Scalars['ID'];
  items?: Maybe<Array<ExtraEquipmentItemInput>>;
};

export type VehicleCardExtraEquipmentPayload = ResponsePayload & {
  __typename?: 'VehicleCardExtraEquipmentPayload';
  errors?: Maybe<Array<BusinessError>>;
  extraEquipment?: Maybe<VehicleCardExtraEquipment>;
};

export type VehicleCardExtraEquipmentUpdateInput = {
  extraEquipmentId: Scalars['ID'];
  items?: Maybe<Array<ExtraEquipmentItemInput>>;
};

export type VehicleCardFilter = {
  brand?: Maybe<Array<Scalars['ID']>>;
  line?: Maybe<Array<Scalars['String']>>;
  model?: Maybe<Scalars['String']>;
  modelYear?: Maybe<Array<Scalars['Int']>>;
  state?: Maybe<Array<VehicleCardStateEnum>>;
  equipment?: Maybe<Scalars['String']>;
  engine?: Maybe<Array<Scalars['String']>>;
  vehicleType?: Maybe<Array<Scalars['ID']>>;
  commission?: Maybe<Scalars['String']>;
  price?: Maybe<PriceFilter>;
  financeType?: Maybe<Array<FinanceTypeEnum>>;
  offerValidity?: Maybe<DateFilter>;
  deliveryDate?: Maybe<DateFilter>;
  updated?: Maybe<DateTimeFilter>;
  optionCode?: Maybe<Scalars['String']>;
  isInStoreCar?: Maybe<Scalars['Boolean']>;
};

export type VehicleCardFinance = {
  __typename?: 'VehicleCardFinance';
  id: Scalars['ID'];
  bonuses?: Maybe<Array<Bonus>>;
  autoCmsDiscountCRN?: Maybe<Scalars['BigDecimal']>;
  autoCmsDiscountPP?: Maybe<Scalars['BigDecimal']>;
  coinsurance?: Maybe<Scalars['Float']>;
  financeGroups?: Maybe<Array<FinanceGroup>>;
};

export type VehicleCardFinanceCreateInput = {
  cardId: Scalars['ID'];
  bonusIds?: Maybe<Array<Scalars['ID']>>;
  autoCmsDiscountCRN?: Maybe<Scalars['BigDecimal']>;
  autoCmsDiscountPP?: Maybe<Scalars['BigDecimal']>;
  coinsurance?: Maybe<Scalars['Float']>;
  financeGroups?: Maybe<Array<FinanceGroupInput>>;
};

export type VehicleCardFinancePayload = ResponsePayload & {
  __typename?: 'VehicleCardFinancePayload';
  errors?: Maybe<Array<BusinessError>>;
  finance?: Maybe<VehicleCardFinance>;
};

export type VehicleCardFinanceUpdateInput = {
  id: Scalars['ID'];
  bonusIds?: Maybe<Array<Scalars['ID']>>;
  autoCmsDiscountCRN?: Maybe<Scalars['BigDecimal']>;
  autoCmsDiscountPP?: Maybe<Scalars['BigDecimal']>;
  coinsurance?: Maybe<Scalars['Float']>;
  financeGroups?: Maybe<Array<FinanceGroupInput>>;
};

export type VehicleCardMarketing = {
  __typename?: 'VehicleCardMarketing';
  id: Scalars['ID'];
  marketingTags?: Maybe<Array<MarketingTagEnum>>;
};

export type VehicleCardMarketingCreateInput = {
  cardId: Scalars['ID'];
  marketingTags?: Maybe<Array<MarketingTagEnum>>;
};

export type VehicleCardMarketingPayload = ResponsePayload & {
  __typename?: 'VehicleCardMarketingPayload';
  errors?: Maybe<Array<BusinessError>>;
  marketing?: Maybe<VehicleCardMarketing>;
};

export type VehicleCardMarketingUpdateInput = {
  id: Scalars['ID'];
  marketingTags?: Maybe<Array<MarketingTagEnum>>;
};

export type VehicleCardPage = {
  __typename?: 'VehicleCardPage';
  data: Array<VehicleCard>;
  pageInfo: PageInfo;
};

export type VehicleCardPayload = ResponsePayload & {
  __typename?: 'VehicleCardPayload';
  errors?: Maybe<Array<BusinessError>>;
  vehicleCard?: Maybe<VehicleCard>;
};

export enum VehicleCardStateEnum {
  InProgress = 'IN_PROGRESS',
  AwaitApproval = 'AWAIT_APPROVAL',
  Approved = 'APPROVED',
  Published = 'PUBLISHED',
  Sold = 'SOLD',
  Archived = 'ARCHIVED',
  Unarchive = 'UNARCHIVE'
}

export type VehicleCardStateUpdateInput = {
  cardId: Scalars['ID'];
  state: VehicleCardStateEnum;
};

export type VehicleCardUpdateInput = {
  id: Scalars['ID'];
  commission: Scalars['String'];
  offerValidTo?: Maybe<Scalars['LocalDate']>;
  deliveryDate?: Maybe<VehicleDeliveryInput>;
  definitionId: Scalars['ID'];
};

export type VehicleDefinition = {
  __typename?: 'VehicleDefinition';
  id: Scalars['ID'];
  auditInfo: AuditInfo;
  state: VehicleDefinitionStateEnum;
  modelDefinition?: Maybe<ModelDefinition>;
  equipment?: Maybe<ModelEquipment>;
  vehicleSpec?: Maybe<TechnicalSpecification>;
};

export type VehicleDefinitionArchivedStateUpdateInput = {
  id: Scalars['ID'];
  archived: Scalars['Boolean'];
};

export type VehicleDefinitionCopyInput = {
  id: Scalars['ID'];
  model: ModelDefinitionCopyInput;
};

export type VehicleDefinitionDuplicateInput = {
  id: Scalars['ID'];
  modelDefinition?: Maybe<Scalars['Boolean']>;
  equipment?: Maybe<Scalars['Boolean']>;
  vehicleSpec?: Maybe<Scalars['Boolean']>;
};

export type VehicleDefinitionFilter = {
  brand?: Maybe<Array<Scalars['ID']>>;
  line?: Maybe<Array<Scalars['String']>>;
  model?: Maybe<Array<Scalars['String']>>;
  modelYear?: Maybe<Array<Scalars['Int']>>;
  state?: Maybe<Array<VehicleDefinitionStateEnum>>;
  equipment?: Maybe<Array<Scalars['ID']>>;
  engine?: Maybe<Array<Scalars['String']>>;
  priceFrom?: Maybe<Scalars['BigDecimal']>;
  priceTo?: Maybe<Scalars['BigDecimal']>;
  updatedFrom?: Maybe<Scalars['LocalDateTime']>;
  updatedTo?: Maybe<Scalars['LocalDateTime']>;
  optionCode?: Maybe<Scalars['String']>;
};

export type VehicleDefinitionPage = {
  __typename?: 'VehicleDefinitionPage';
  data: Array<VehicleDefinition>;
  pageInfo: PageInfo;
};

export type VehicleDefinitionPayload = ResponsePayload & {
  __typename?: 'VehicleDefinitionPayload';
  errors?: Maybe<Array<BusinessError>>;
  vehicleDefinition?: Maybe<VehicleDefinition>;
};

export enum VehicleDefinitionStateEnum {
  WaitModel = 'WAIT_MODEL',
  WaitEquipment = 'WAIT_EQUIPMENT',
  WaitSpecification = 'WAIT_SPECIFICATION',
  Active = 'ACTIVE',
  Archived = 'ARCHIVED'
}

export type VehicleDefinitionUpdateInput = {
  id: Scalars['ID'];
  modelDefinitionId?: Maybe<Scalars['ID']>;
  equipmentId?: Maybe<Scalars['ID']>;
  vehicleSpecId?: Maybe<Scalars['ID']>;
};

export type VehicleDelivery = {
  __typename?: 'VehicleDelivery';
  exactDate?: Maybe<Scalars['LocalDate']>;
  plusDays?: Maybe<Scalars['Int']>;
  immediatePickUp: Scalars['Boolean'];
};

export type VehicleDeliveryInput = {
  exactDate?: Maybe<Scalars['LocalDate']>;
  plusDays?: Maybe<Scalars['Int']>;
  immediatePickUp: Scalars['Boolean'];
};

export type VehicleType = CodetableDto & {
  __typename?: 'VehicleType';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  state?: Maybe<CodetableStateEnum>;
};

export enum VehicleTypeEnum {
  Personal = 'PERSONAL',
  Commercial = 'COMMERCIAL'
}

export type VehicleTypePayload = ResponsePayload & {
  __typename?: 'VehicleTypePayload';
  errors?: Maybe<Array<BusinessError>>;
  data?: Maybe<VehicleType>;
};

export type WheelbaseType = CodetableDto & {
  __typename?: 'WheelbaseType';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  state?: Maybe<CodetableStateEnum>;
};

export type WheelbaseTypePayload = ResponsePayload & {
  __typename?: 'WheelbaseTypePayload';
  errors?: Maybe<Array<BusinessError>>;
  data?: Maybe<WheelbaseType>;
};
