import React, { useEffect } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid } from '@mui/material';
import { useTranslation } from "react-i18next";
import { UsedVehicleImage } from '../../../api/graphql/generated/schema';
import { setLoadingStatus } from "../../../redux/loading";
import { useDispatch } from "react-redux";
import { ApolloError, ColorImage } from "../../../components";
import { openErrorSnackbar, openSuccessSnackbar } from "../../../redux/snackbar";
import { getBusinessErrorMessages, getGQLErrorMessages } from "../../../utils/graphGL/graphQLHelper";
import { Add, ExpandMore } from '@mui/icons-material';
import { DropzoneDialogBase, FileObject } from 'react-mui-dropzone';
import {
    DeleteUsedVehicleImageMutationOptions,
    UploadUsedVehicleImageMutationOptions,
    useDeleteUsedVehicleImageMutation,
    useUploadUsedVehicleImageMutation,
} from '../../../api/graphql/generated/hooks';

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export default function UsedCarPhotos(props: { images: Array<UsedVehicleImage>, handleUpdate: Function, initiallyExpanded?: boolean }) {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [accordionExpanded, setAccordionExpanded] = React.useState<boolean | undefined>(props.initiallyExpanded)
    const [openDropZoneDialog, setOpenDropZoneDialog] = React.useState<boolean>(false);
    const [fileObjects, setFileObjects] = React.useState<FileObject[]>([]);
    const [uploadImageMutation, {loading: loadingUploadImageMutation}] = useUploadUsedVehicleImageMutation();
    const [deleteImageMutation, {loading: loadingDeleteImageMutation}] = useDeleteUsedVehicleImageMutation();

    useEffect(() => {
        dispatch(setLoadingStatus(loadingUploadImageMutation ));
    }, [dispatch, loadingUploadImageMutation, loadingDeleteImageMutation]);

    const handleUploadImage = (base64: string) => {
        let inputOptions: UploadUsedVehicleImageMutationOptions = {
            variables: {
                input: {
                    content: base64
                }
            },
            onError: (error) => <ApolloError error={[error]}/>
        };
        uploadImageMutation(inputOptions)
            .then(response => {
                if (response?.data?.uploadUsedVehicleImage?.imageId) {
                    dispatch(openSuccessSnackbar(t("userMessages.vehicleCard.photos.imageUploaded")));
                    const images = props.images;
                    images.push( {
                        id : response.data.uploadUsedVehicleImage.imageId,
                        fileName: response.data.uploadUsedVehicleImage.fileName,
                        order: 1,
                        mainImage: false,
                        publicUrl: response.data.uploadUsedVehicleImage.publicUrl
                    });
                    props.handleUpdate(images);
                } else if (response?.data?.uploadUsedVehicleImage?.errors) {
                    response.data.uploadUsedVehicleImage.errors.forEach(error => dispatch(openErrorSnackbar( getBusinessErrorMessages(error, t, "vehicleCard"))));
                } else if(response.errors){
                    response.errors.forEach(error => dispatch(openErrorSnackbar(getGQLErrorMessages(error))));
                }
            })
            .catch(error => dispatch(openErrorSnackbar(getGQLErrorMessages(error))));
    }

    const handleRemoveImage = (imageId: string, fileName: string) => {
        let inputOptions: DeleteUsedVehicleImageMutationOptions = {
            variables: {
                input: {
                    imageId: imageId,
                    fileName: fileName
                }
            },
            onError: (error) => <ApolloError error={[error]}/>
        };
        deleteImageMutation(inputOptions)
            .then(response => {
                if (response?.data?.deleteUsedVehicleImage?.success) {
                    dispatch(openSuccessSnackbar(t("userMessages.vehicleCard.photos.imageDeleted")));
                    props.handleUpdate(props.images.filter((img) => img.id !== imageId));
                } else if (response?.data?.deleteUsedVehicleImage?.errors) {
                    response.data.deleteUsedVehicleImage.errors.forEach(error => dispatch(openErrorSnackbar( getBusinessErrorMessages(error, t, "vehicleCard"))));
                } else if(response.errors){
                    response.errors.forEach(error => dispatch(openErrorSnackbar(getGQLErrorMessages(error))));
                }
            })
            .catch(error => dispatch(openErrorSnackbar(getGQLErrorMessages(error))));
    }


    const handleSaveImage = (file: FileObject) => {
        toBase64(file.file)
            .then(b64 => b64 as string)
            .then(b64String => handleUploadImage(b64String));
    }

    const handleOnSaveImages = () => {
        fileObjects.forEach(fileObject => handleSaveImage(fileObject));
        setFileObjects([]);
        setOpenDropZoneDialog(false);
    }

    const handleChangeMainImage = (imgId: string) => {
        const imgIndex = props.images?.findIndex((img) => img.id === imgId );
        props.images[imgIndex].mainImage = !props.images[imgIndex].mainImage;
        props.handleUpdate(props.images);
    }

    return (
        <Accordion className="card-section" variant="outlined" expanded={accordionExpanded}
                   onChange={(event, expanded) => setAccordionExpanded(expanded)}>
            <AccordionSummary
                sx={{ flexDirection: 'row-reverse' }}
                expandIcon={<ExpandMore color='secondary' />}
            >
                <h2>{t("pages.vehicleCards.detail.photos.title")}</h2>
            </AccordionSummary>
            <AccordionDetails>
                <Box mb={2}>
                    <Grid container direction={"row"} justifyContent={"flex-end"}>
                        <Button variant="outlined" onClick={() => setOpenDropZoneDialog(true)}>
                            <Add/>{t("actions.add")}
                        </Button>
                    </Grid>
                </Box>
                <Box mb={2}>
                    <Grid container direction={"row"} justifyContent={"space-evenly"} spacing={1}>
                        {props.images?.map(img => (
                            <Grid item>
                                <ColorImage
                                    imageId={img.id}
                                    imageUrl={img.publicUrl}
                                    isMainImage={img?.mainImage || false}
                                    handleUpdate={() => handleChangeMainImage(img.id)}
                                    handleRemove={() => handleRemoveImage(img.id, img.fileName)}/>
                            </Grid>
                        ))}
                    </Grid>
                    <DropzoneDialogBase
                        dialogTitle={t("pages.vehicleCards.detail.photos.dropzone.title")}
                        acceptedFiles={['image/*']}
                        fileObjects={fileObjects}
                        cancelButtonText={t("actions.discard")}
                        submitButtonText={t("actions.save")}
                        maxFileSize={5000000}
                        filesLimit={10}
                        dropzoneText={t("pages.vehicleCards.detail.photos.dropzone.tooltip")}
                        open={openDropZoneDialog}
                        onAdd={newFileObjs => {
                            setFileObjects([...fileObjects, ...newFileObjs]);
                        }}
                        onDelete={(deleteFileObj, index) => {
                            setFileObjects(fileObjects.filter(fo => fo.file.name !== deleteFileObj.file.name));
                        }}
                        onClose={() => {
                            setFileObjects([]);
                            setOpenDropZoneDialog(false);
                        }}
                        onSave={handleOnSaveImages}
                        showPreviews={true}
                        showFileNamesInPreview={true}
                    />
                </Box>
            </AccordionDetails>
        </Accordion>
    );
}
