import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextField, Typography
} from '@mui/material';
import { useTranslation } from "react-i18next";
import {
  CreateVehicleCardFinanceMutationOptions,
  UpdateVehicleCardFinanceMutationOptions,
  useCreateVehicleCardFinanceMutation,
  useUpdateVehicleCardFinanceMutation,
} from "../../../api/graphql/generated/hooks";
import {
  ApolloError,
  BonusDialog,
  ColorsViewer,
  EmptyDataInfo,
  FinanceGroup,
  GraphQLError,
  Loader
} from "../../../components";
import { BonusDto, CardFinanceDto, FinanceGroupDto } from "../../../modelView";
import { formatToZeroDecimalsString } from "../../../utils/formatterHelper";
import { openErrorSnackbar, openSuccessSnackbar } from "../../../redux/snackbar";
import { getBusinessErrorMessages } from "../../../utils/graphGL/graphQLHelper";
import { TotalPricesType } from "../../../components/vehicleCard/finance/FinanceGroup";
import { coinsuranceValues } from "../../../utils/const";
import { convertToFinanceGroupsInput } from "../../../modelView/finance/financeGroupConverter";
import { Bonus } from "../../../api/graphql/generated/schema";
import { AttachMoneyOutlined, ExpandMore, SaveOutlined } from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';

const initCoInsurance = (coinsurance: number | null): string => {
  return coinsurance ? coinsurance.toString() : "";
}

const initState = {
  userBonuses: [],
  openBonusDialog: false,
  selectedFinanceGroupTab: "",
  openRemoveFGDialog: false,
  financeGroupToDelete: null,

}

function selectFirsTab(financeGroups: Array<FinanceGroupDto>) {
  return financeGroups && financeGroups.length > 0 ? financeGroups[0].id : initState.selectedFinanceGroupTab;
}

export default function CardFinance(props: { financeData: CardFinanceDto, brandId: string, vehicleTypeId: string, initiallyExpanded?: boolean, handleUpdate: Function }) {
  const changedInput = (setFunction) => (value: any) => {
    setFunction(value);
    setChanged(true);
  }

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [changed, setChanged] = useState(false);
  //Move to separate component and handle state there
  const [totalBonusesCRN, setTotalBonusesCRN] = React.useState<string>(props.financeData.autoCmsDiscountCRN.toString());
  const [totalBonusesPP, setTotalBonusesPP] = React.useState<string>(props.financeData.autoCmsDiscountCRN.toString());
  const [openBonusDialog, setOpenBonusDialog] = useState<boolean>(initState.openBonusDialog);
  const [bonuses, setBonuses] = useState<Array<BonusDto>>(initState.userBonuses)

  const [autoCmsDiscountCRN, setautoCmsDiscountCRN] = React.useState<string>(props.financeData.autoCmsDiscountCRN.toString());
  const [autoCmsDiscountPP, setautoCmsDiscountPP] = React.useState<string>(props.financeData.autoCmsDiscountPP.toString());
  const [coinsurance, setCoinsurance] = React.useState<string>(initCoInsurance(props.financeData.coinsurance));

  const [createVehicleCardFinanceMutation, { loading: loadingCreateVCF, error: errorCreateVCF }] = useCreateVehicleCardFinanceMutation();
  const [updateVCFM, { loading: loadingUpdateVCF, error: errorUpdateVCF }] = useUpdateVehicleCardFinanceMutation();

  const [accordionExpanded, setAccordionExpanded] = React.useState<boolean | undefined>(props.initiallyExpanded)
  const [selectedFinanceGroupTab, setSelectedFinanceGroupTab] = React.useState(selectFirsTab(props.financeData.financeGroups));
  const [financeGroups, setFinanceGroups] = React.useState<Array<FinanceGroupDto>>(props.financeData.financeGroups);

  const [totalPrices, setTotalPrices] = useState<TotalPricesType | null>(null);

  const [openRemoveFGDialog, setOpenFGRemoveDialog] = useState<boolean>(initState.openRemoveFGDialog);
  const [financeGroupToDelete, setFinanceGroupToDelete] = useState<FinanceGroupDto | null>(initState.financeGroupToDelete);

  useEffect(() => {
    setTotalPrices({
      model: props.financeData.modelPrice,
      extraEquipment: props.financeData.totalEquipment,
      totalBonusesCRN: Number(totalBonusesCRN),
      totalBonusesPP: Number(totalBonusesPP),
      discountOcCrn: Number(autoCmsDiscountCRN),
      discountOcPP: Number(autoCmsDiscountPP)
    });
  }, [totalBonusesCRN, totalBonusesPP, autoCmsDiscountCRN, autoCmsDiscountPP, props.financeData.modelPrice, props.financeData.totalEquipment])

  const initBonuses = (_bonuses: Array<Bonus>) => {
    if (_bonuses) {
      setUpdateBonuses(_bonuses.map(inBonus => BonusDto.convertFromBonusGQL(inBonus)))
    } else {
      setUpdateBonuses(initState.userBonuses);
    }
  }

  useEffect(() => {
    initBonuses(props.financeData.bonuses);
  }, [props.financeData.bonuses]);

  const initFinanceGroups = (_financeGroups: Array<FinanceGroupDto>) => {
    setFinanceGroups(_financeGroups);
    setSelectedFinanceGroupTab(selectFirsTab(_financeGroups));
  }

  useEffect(() => {
    initFinanceGroups(props.financeData.financeGroups);
  }, [props.financeData.financeGroups]);

  useEffect(() => {
    setautoCmsDiscountCRN(props.financeData.autoCmsDiscountCRN.toString());

  }, [props.financeData.autoCmsDiscountCRN]);

  useEffect(() => {
    setautoCmsDiscountPP(props.financeData.autoCmsDiscountPP.toString());

  }, [props.financeData.autoCmsDiscountPP]);

  useEffect(() => {
    setCoinsurance(initCoInsurance(props.financeData.coinsurance));

  }, [props.financeData.coinsurance]);

  const changeautoCmsDiscountCRN = (value: string) => {
    changedInput(setautoCmsDiscountCRN)(value);
  }

  const changeautoCmsDiscountPP = (value: string) => {
    changedInput(setautoCmsDiscountPP)(value);
  }

  const changeCoinsurance = (value: string) => {
    changedInput(setCoinsurance)(value);
  }

  const handleOpenBonusDialog = () => {
    setOpenBonusDialog(true);
  }

  const handleCloseBonusDialog = () => {
    setOpenBonusDialog(false);
  }

  const setUpdateBonuses = (_bonuses: Array<BonusDto>) => {
    setBonuses(_bonuses);
    let _totalBonusesCRN = _bonuses.filter(bonus => bonus.forCrn).map(bonus => bonus.price).reduce((b1, b2) => b1 + b2, 0);
    setTotalBonusesCRN(formatToZeroDecimalsString(_totalBonusesCRN));
    let _totalBonusesPP = _bonuses.filter(bonus => bonus.forPhysicalPerson).map(bonus => bonus.price).reduce((b1, b2) => b1 + b2, 0);
    setTotalBonusesPP(formatToZeroDecimalsString(_totalBonusesPP));
  }

  const handleUpdateBonuses = (_bonuses: Array<BonusDto>) => {
    setUpdateBonuses(_bonuses);
    setChanged(true);
  }

  const handleCreateVCF = () => {
    let inputOptions: CreateVehicleCardFinanceMutationOptions = {
      variables: {
        input: {
          cardId: props.financeData.cardId,
          autoCmsDiscountCRN: autoCmsDiscountCRN,
          autoCmsDiscountPP: autoCmsDiscountPP,
          coinsurance: parseFloat(coinsurance),
          bonusIds: bonuses.map(bonus => bonus.id),
          financeGroups: convertToFinanceGroupsInput(financeGroups)
        }
      },
      onError: (error) => <ApolloError error={[error]}/>
    };
    createVehicleCardFinanceMutation(inputOptions)
      .then(response => {
        if (response.data?.createVehicleCardFinance?.finance?.id) {
          props.handleUpdate();
          dispatch(openSuccessSnackbar(t("userMessages.vehicleCard.finance.created")));
        } else if (response.data?.createVehicleCardFinance?.errors) {
          response.data.createVehicleCardFinance.errors.forEach(error => dispatch(openErrorSnackbar(getBusinessErrorMessages(error, t, "vehicleCard.finance"))));
        } else if (response.errors) {
          return <GraphQLError error={response.errors}/>
        }
      })
      .then(() => setChanged(false))
      .catch(error => <ApolloError error={error}/>);
  }

  const handleUpdateVCF = (cardFinanceId: string) => {
    let inputOptions: UpdateVehicleCardFinanceMutationOptions = {
      variables: {
        input: {
          id: cardFinanceId,
          autoCmsDiscountCRN: autoCmsDiscountCRN,
          autoCmsDiscountPP: autoCmsDiscountPP,
          coinsurance: parseFloat(coinsurance),
          bonusIds: bonuses.map(bonus => bonus.id),
          financeGroups: convertToFinanceGroupsInput(financeGroups)
        }
      },
      onError: (error) => <ApolloError error={[error]}/>
    };
    updateVCFM(inputOptions)
      .then(response => {
        if (response?.data?.updateVehicleCardFinance?.finance?.id) {
          props.handleUpdate();
          dispatch(openSuccessSnackbar(t("userMessages.vehicleCard.finance.updated")));
        } else if (response.data?.updateVehicleCardFinance?.errors) {
          response.data.updateVehicleCardFinance.errors.forEach(error => dispatch(openErrorSnackbar(getBusinessErrorMessages(error, t, "vehicleCard.finance"))));
        } else if (response.errors) {
          return <GraphQLError error={response.errors}/>
        }
      })
      .then(() => setChanged(false))
      .catch(error => <ApolloError error={error}/>);
  }

  const handleSave = () => {
    if (props.financeData.id) {
      handleUpdateVCF(props.financeData.id);
    } else {
      handleCreateVCF();
    }
  }

  const handleFinanceGroupTabChange = (event: React.ChangeEvent<{}>, data: string) => {
    setSelectedFinanceGroupTab(data);
  }

  const updateFinanceGroup = (updatedFinanceGroup: FinanceGroupDto) => {
    let updFinanceGroups = financeGroups.filter(fg => fg.id !== updatedFinanceGroup.id);
    updFinanceGroups.push(updatedFinanceGroup);
    setFinanceGroups(updFinanceGroups);
    setChanged(true);
  }

  const removeFinanceGroup = () => {
    if (financeGroupToDelete) {
      let updFinanceGroups = financeGroups.filter(fg => fg.id !== financeGroupToDelete.id);
      setFinanceGroups(updFinanceGroups);
      setChanged(true);
      discardRemoveFinanceGroupDialog();
      setSelectedFinanceGroupTab(selectFirsTab(updFinanceGroups));
    } else {
      dispatch(openErrorSnackbar(t("userError.vehicleCard.finance.removeFinanceGroup.missingFinanceGroup")));
    }
  }

  const displayRemoveFinanceGroupDialog = (financeGroup: FinanceGroupDto) => {
    if (financeGroup.colors.length > 0) {
      return null;
    } else {
      setFinanceGroupToDelete(financeGroup);
      setOpenFGRemoveDialog(true);
    }
  }

  const discardRemoveFinanceGroupDialog = () => {
    setOpenFGRemoveDialog(false);
    setFinanceGroupToDelete(initState.financeGroupToDelete);
  }

  const handleReset = () => {
    setChanged(false);
    initFinanceGroups(props.financeData.financeGroups);
    initBonuses(props.financeData.bonuses);
    setautoCmsDiscountCRN(props.financeData.autoCmsDiscountCRN.toString());
    setautoCmsDiscountPP(props.financeData.autoCmsDiscountPP.toString());
    setCoinsurance(initCoInsurance(props.financeData.coinsurance));
  }

  const renderFinanceGroups = (_totalPrices: TotalPricesType) => {
    return (
      <TabContext value={selectedFinanceGroupTab}>
        <Tabs
          value={selectedFinanceGroupTab}
          variant="scrollable"
          indicatorColor="primary"
          textColor="primary"
          onChange={handleFinanceGroupTabChange}
        >
          {financeGroups.map(financeGroup =>
            <Tab value={financeGroup.id}
                 icon={<ColorsViewer inputValues={financeGroup.colors} displayMissingInfo={true}/>}
                 wrapped={true}
                 label={t("pages.vehicleCards.detail.finance.tabColorPriceLabel", { price: financeGroup.groupPrice })}
                 onClick={() => displayRemoveFinanceGroupDialog(financeGroup)}
            />
          )}
        </Tabs>
        {financeGroups.map((item) => (
          <TabPanel value={item.id}>
            <FinanceGroup
              financeGroup={item}
              totalPrices={_totalPrices}
              handleUpdate={updateFinanceGroup}
            />
          </TabPanel>
        ))}
      </TabContext>
    );
  };

  const renderRemoveFinanceGroupDialog = () => {
    return (
      <Dialog
        open={openRemoveFGDialog}
        onClose={discardRemoveFinanceGroupDialog}
        maxWidth={"sm"}
        fullWidth={true}
      >
        <DialogTitle id={'finance-group-remove-confirm-dialog'}>
          {t("pages.vehicleCards.detail.finance.removeFinanceGroupDialog.title")}
        </DialogTitle>
        <DialogContent>
          {t("pages.vehicleCards.detail.finance.removeFinanceGroupDialog.dialogText", { "groupPrice": financeGroupToDelete?.groupPrice })}
        </DialogContent>
        <DialogActions>
          <Button onClick={discardRemoveFinanceGroupDialog}
                  value={'Cancel'}
          >
            {t('actions.keep')}
          </Button>
          <Button
            onClick={() => removeFinanceGroup()}
            title={'Approve'}
            className="icon-button">
            {t('actions.remove')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  if (loadingCreateVCF || loadingUpdateVCF) return <Loader/>;
  if (errorCreateVCF) return <ApolloError error={[errorCreateVCF]}/>;
  if (errorUpdateVCF) return <ApolloError error={[errorUpdateVCF]}/>;

  return (
    <form id="model-finance-form">
      <Accordion className="card-section" variant="outlined" expanded={accordionExpanded} onChange={(event, expanded) => setAccordionExpanded(expanded)}>
        <AccordionSummary
          sx={{ flexDirection: 'row-reverse' }}
          expandIcon={<ExpandMore color='secondary'/>}
        >
          <h2>{t("pages.vehicleCards.detail.finance.title")}</h2>
        </AccordionSummary>
        <AccordionDetails>

          <Box mb={2}>
            <Typography variant={"body1"}>
              <strong>{t('pages.vehicleCards.detail.finance.discounts')}</strong>
            </Typography>
          </Box>
          <Box mb={2}>
            <Grid container direction="row" xs={12}>
              <Grid item xs={12} sm={4}>
                <FormControl className="outlined">
                  <TextField
                    id="total-bonuses-crn"
                    label={t('fieldName.totalBonusesCRN')}
                    type="text"
                    value={totalBonusesCRN}
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl className="outlined">
                  <TextField
                    id="total-bonuses-pp"
                    label={t('fieldName.totalBonusesPP')}
                    type="text"
                    value={totalBonusesPP}
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl>
                  <Button size={"large"} onClick={handleOpenBonusDialog}>
                    <AttachMoneyOutlined/>
                    {t('pages.vehicleCards.detail.finance.bonuses')}
                  </Button>
                </FormControl>
                <BonusDialog bonuses={bonuses} brandId={props.brandId} vehicleTypeId={props.vehicleTypeId} open={openBonusDialog} handleClose={handleCloseBonusDialog}
                             handleUpdate={handleUpdateBonuses}/>
              </Grid>
            </Grid>
          </Box>
          <Box mb={2}>
            <Grid container direction="row" xs={12}>
              <Grid item xs={12} sm={4}>
                <FormControl className="outlined">
                  <TextField
                    id="auto-cms-discount-crn"
                    label={t('fieldName.autoCmsDiscountCRN')}
                    type="number"
                    value={autoCmsDiscountCRN}
                    required
                    error={!autoCmsDiscountCRN}
                    onChange={event => changeautoCmsDiscountCRN(event.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl className="outlined">
                  <TextField
                    id="auto-cms-discount-pp"
                    label={t('fieldName.autoCmsDiscountPP')}
                    type="number"
                    value={autoCmsDiscountPP}
                    required
                    error={!autoCmsDiscountPP}
                    onChange={event => changeautoCmsDiscountPP(event.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth component="fieldset">
                  <FormLabel component="legend">{t('fieldName.coinsurance')}</FormLabel>
                  <RadioGroup row name="coinsurance"
                              value={coinsurance}
                              onChange={event => changeCoinsurance(event.target.value)}>
                    <FormControlLabel value={coinsuranceValues.FIVE} control={<Radio/>} label="5%"/>
                    <FormControlLabel value={coinsuranceValues.TEN} control={<Radio/>} label="10%"/>
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <Typography variant={"body1"}>
            <strong className="text-left d-block mb-4">{t('pages.vehicleCards.detail.finance.leasingCreditTitle')}</strong>
          </Typography>

          <Box mb={2}>
            {totalPrices && props.financeData.financeGroups && props.financeData.financeGroups.length > 0 ? renderFinanceGroups(totalPrices) :
              <EmptyDataInfo messageToDisplay={t("userError.vehicleCard.finance.financeGroupMissingColor")}/>}
            {renderRemoveFinanceGroupDialog()}
          </Box>
        </AccordionDetails>
        <AccordionActions>
          <div className="mt-4">
            <Grid item xs={12} sm={12} className="text-right">
              <Button onClick={handleReset} disabled={!changed} className="reset-button">{t('pages.vehicleCards.detail.actions.reset')}</Button>
              <Button onClick={handleSave} disabled={!changed}
                      className="icon-button"><SaveOutlined/> {props.financeData.id ? t('pages.vehicleCards.detail.actions.save') : t('pages.vehicleCards.detail.finance.actions.create')}</Button>
            </Grid>
          </div>
        </AccordionActions>
      </Accordion>
    </form>

  );

}
