import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, TextField, Theme, Toolbar, Typography } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import React, { useCallback, useEffect, useState } from 'react';
import {
    Equipment as EquipmentType,
    EquipmentCategory as EquipmentCategoryType,
    ModelEquipmentItemInput as ModelEquipmentItemInputType,
    UsedVehicleEquipmentItem as UsedVehicleEquipmentItemType,
} from '../../../api/graphql/generated/schema';
import { useGetEquipmentCategoriesQuery, useGetEquipmentsQuery } from '../../../api/graphql/generated/hooks';
import { createStyles, makeStyles } from '@mui/styles';
import { UserEquipmentItemDto } from './equipmentItems/UserEquipmentItemDto';
import EquipmentItemsEditor, { UserEquipmentsCategoryType } from './equipmentItems/EquipmentItemsEditor';
import { setSnackbar, SnackbarStates } from '../../../redux/snackbar';
import EquipmentItemInput from './equipmentItems/EquipmentItemInput';
import { setLoadingStatus } from '../../../redux/loading';
import { assembleModelEquipmentsByCode } from './equipment.utils';
import { EquipmentDto } from '../../../modelView';
import { EquipmentItemDto } from '../../../modelView';
import { ApolloError } from '../../index';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
    }),
);

const initUserDataState = {
    'inputEquipmentName': '',
    'possibleEquipmentCategory': [],
    'inputModelEquipmentSortNumber': null,
}

export type UpdatedEquipments = {
    model: ModelEquipmentItemInputType,
    usedVehicle: UsedVehicleEquipmentItemType | null,
}

export default function EquipmentEditorDialog(
    props: {
        open: boolean,
        equipment: EquipmentDto | null,
        handleClose,
        handleReset,
        handleSubmit,
    }) {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    const [changed, setChanged] = useState<boolean>(false);
    const [userEquipmentName, setUserEquipmentName] = React.useState<string | null>(initUserDataState.inputEquipmentName);
    const [userEquipmentsByCategory, setUserEquipmentsByCategory] = React.useState<UserEquipmentsCategoryType[]>(initUserDataState.possibleEquipmentCategory);
    const [possibleEquipmentsCategories, setPossibleEquipmentsCategories] = React.useState<EquipmentCategoryType[]>(initUserDataState.possibleEquipmentCategory);
    const [userModelEquipmentSortNumber, setUserModelEquipmentSortNumber] = React.useState<string | null>(initUserDataState.inputModelEquipmentSortNumber);

    const { loading: loadEquipments, data: gqlEquipments, error: errorEquipments } = useGetEquipmentsQuery();
    const { loading: loadEquipmentCategories, data: gqlEquipmentCategories, error: errorEquipmentCategories } = useGetEquipmentCategoriesQuery();

    useEffect(() => {
        dispatch(setLoadingStatus(loadEquipmentCategories || loadEquipments));
    }, [loadEquipmentCategories, loadEquipments])

    useEffect(() => {
        if (gqlEquipmentCategories) {
            setPossibleEquipmentsCategories(gqlEquipmentCategories.getEquipmentCategories);
        } else {
            setPossibleEquipmentsCategories(initUserDataState.possibleEquipmentCategory);
        }
    }, [gqlEquipmentCategories]);

    useEffect(() => {
        if (props.equipment) {
            setUserEquipmentName(props.equipment.name);
            setUserModelEquipmentSortNumber(props.equipment.sortNumber !== null ? props.equipment.sortNumber.toString() : null);
        } else {
            setUserEquipmentName(initUserDataState.inputEquipmentName);
            setUserModelEquipmentSortNumber(initUserDataState.inputModelEquipmentSortNumber);
        }

    }, [props.equipment]);

    useEffect(() => {
        let userEquipmentItems: UserEquipmentItemDto[] = [];
        if (props.equipment?.equipments) {
            props.equipment.equipments.forEach(mei => userEquipmentItems.push(mei));
        }
        if (gqlEquipments?.getEquipments) {
            gqlEquipments?.getEquipments
                .filter(equipment => (userEquipmentItems.find(item => item.equipmentId === equipment.id) === undefined))
                .map(UserEquipmentItemDto.convertEquipmentToUserEquipmentItemType)
                .map(item => userEquipmentItems.push(item));
        }

        setUserEquipmentsByCategory(assembleModelEquipmentsByCode(userEquipmentItems));
    }, [props.equipment?.equipments, gqlEquipments]);

    const reset = () => {
        setChanged(false);
        props.handleReset();
    };

    const handleClose = () => {
        reset();
        props.handleClose();
    };

    const handleChangeModelEquipment = (originalValue: string | number | null, updateFunction: Function, validationFunction?: Function) => (event) => {
        if (originalValue !== event.target.value && (!validationFunction || validationFunction(event.target.value))) {
            setChanged(true);
            updateFunction(event.target.value);
        } else {
            setChanged(false);
        }
    }

    const validateSortNumber = (value: string) => {
        return !value || !isNaN(parseInt(value));
    }

    const getUpdatedItems = (): EquipmentItemDto[] => {
        return userEquipmentsByCategory.flatMap(catItems => catItems.equipmentItems)
            .filter(item => item.isApplied)
            .map(item => {
                return new EquipmentItemDto(item.description, item.count, item.modelEquipmentItemDataChanged(), item.equipmentId, item.equipmentName, item.equipmentCategoryId, item.equipmentCategoryLabel, item.equipmentItemDataChanged())

            });
    }

    const updateEquipmentItemRow = (equipmentItem: UserEquipmentItemDto) => {
        let result = false;
        if (equipmentItem.equipmentCategoryChanged()) {
            if (userEquipmentsByCategory.filter(umec => umec.categoryId === equipmentItem.equipmentCategoryId).flatMap(eic => eic.equipmentItems).map(ei => ei.equipmentName).includes(equipmentItem.equipmentName)) {
                dispatch(setSnackbar(true, SnackbarStates.ERROR, t('userError.businessError.equipment.ENTITY_EXISTS_FOR_NAME_CATEGORY')))
            } else {
                userEquipmentsByCategory
                    .forEach(umec => {
                        if (umec.categoryId === equipmentItem.equipmentCategoryId) {
                            umec.equipmentItems[umec.equipmentItems.length] = equipmentItem;
                        }
                    });
                result = true;
            }
        } else {
            userEquipmentsByCategory
                .forEach(umec => {
                    if (umec.categoryId === equipmentItem.equipmentCategoryId) {
                        umec.equipmentItems.forEach((ei, index) => {
                            if (ei.equipmentId === equipmentItem.equipmentId) {
                                umec.equipmentItems[index] = equipmentItem;
                            }
                        })
                    }
                });
            result = true;
        }

        setChanged(true);
        return result;
    };

    const addEquipmentItem = useCallback((equipment: EquipmentType) => {
        setUserEquipmentsByCategory(prev => {
            let newData = UserEquipmentItemDto.convertEquipmentToUserEquipmentItemType(equipment);
            let newUmec = [...prev];
            let cat = newUmec.filter(category => category.categoryId === newData.equipmentCategoryId).map(category => category.equipmentItems.push(newData));
            if (!cat || cat.length === 0) {
                let newCat: UserEquipmentsCategoryType = {
                    categoryId: newData.equipmentCategoryId,
                    categoryLabel: newData.equipmentCategoryLabel,
                    equipmentItems: [newData],

                }
                newUmec.push(newCat);
            }

            return newUmec;
        });
    }, []);

    if (errorEquipments) return <ApolloError error={[errorEquipments]} />;
    if (errorEquipmentCategories) return <ApolloError error={[errorEquipmentCategories]} />;
    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            fullWidth={true}
            fullScreen={true}
        >
            <DialogTitle>
                <Toolbar>
                    <Typography variant="h5" className={classes.title}>
                        {t('pages.definitionEditor.modelEquipment.equipmentDialog.title')}
                    </Typography>
                    <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                        <CloseRounded/>
                    </IconButton>
                </Toolbar>
            </DialogTitle>
            <DialogContent>
                <Grid container justifyContent={'flex-end'} sx={{ p: 1 }}>
                    <Grid item container sx={{ flex: 1 }}>
                        <Grid item>
                            <FormControl required>
                                <TextField
                                    variant="outlined"
                                    label={t('fieldName.name')}
                                    value={userEquipmentName || ''}
                                    required
                                    fullWidth
                                    error={!userEquipmentName}
                                    onChange={(event) => {
                                        setUserEquipmentName(event.target.value);
                                        setChanged(true);
                                    }}
                                    inputProps={{
                                        maxLength: 50,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        {props.equipment?.model && <Grid item>
                            <FormControl required>
                                <TextField
                                    variant="outlined"
                                    label={t('fieldName.sortNumber')}
                                    value={userModelEquipmentSortNumber}
                                    required
                                    fullWidth
                                    error={!userModelEquipmentSortNumber}
                                    onChange={handleChangeModelEquipment(userModelEquipmentSortNumber, setUserModelEquipmentSortNumber, validateSortNumber)}
                                    inputProps={{
                                        maxLength: 1,
                                    }}
                                />
                            </FormControl>
                        </Grid>}
                    </Grid>
                    <Grid item>
                        <Typography>{t('pages.definitionEditor.modelEquipment.equipmentItems.newModelEquipmentItem')}</Typography>
                        <EquipmentItemInput possibleEquipmentsCategories={possibleEquipmentsCategories} addRow={addEquipmentItem}/>
                    </Grid>
                </Grid>
                {userEquipmentsByCategory &&
                    <EquipmentItemsEditor
                        equipmentsByCategories={userEquipmentsByCategory}
                        possibleEquipmentsCategories={possibleEquipmentsCategories}
                        updateRow={updateEquipmentItemRow}
                    />
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={reset}>{t('actions.discard')}</Button>
                <Button disabled={!changed || !userEquipmentName || (props.equipment?.model && !userModelEquipmentSortNumber)} onClick={() => {
                    props.handleSubmit(userEquipmentName, getUpdatedItems(), userModelEquipmentSortNumber ? parseInt(userModelEquipmentSortNumber) : userModelEquipmentSortNumber);
                    handleClose();
                    }}
                >
                    {t('actions.save')}
                </Button>
            </DialogActions>
        </Dialog>
    );

}
