import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Container,
    FormControl,
    Grid,
    IconButton,
    List,
    ListItem,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import PageHeader from '../../../components/pageHeader/PageHeader';
import { AppPageNameRoutes, AppPageNameRouteType } from '../../../routes/paths';
import { setLoadingStatus } from '../../../redux/loading';
import { useDispatch } from 'react-redux';
import { ApolloError, EnumSelect } from '../../../components';
import { BusinessError, ServicePackage, ServicePackageStateEnum } from '../../../api/graphql/generated/schema';
import { openErrorSnackbar, openSuccessSnackbar } from '../../../redux/snackbar';
import { getBusinessErrorMessages, getGQLErrorMessages } from '../../../utils/graphGL/graphQLHelper';
import { servicePackageStates } from '../../../utils/const';
import ServicePackageDialog, { ServicePackageDto } from './ServicePackageDialog';
import { hasRole } from '../../../utils/functionHelper';
import { GraphQLFormattedError } from 'graphql';
import { TFunctionResult } from 'i18next';
import { Add, ArchiveOutlined, EditOutlined, UnarchiveOutlined } from '@mui/icons-material';
import {
    CreateServicePackageMutationOptions, UpdateServicePackageMutationOptions, UpdateServicePackageStateMutationOptions,
    useCreateServicePackageMutation,
    useGetServicePackagesQuery,
    useUpdateServicePackageMutation,
    useUpdateServicePackageStateMutation,
} from '../../../api/graphql/generated/hooks';
import { Role } from '../../../utils/enums';

const breadcrumbs: AppPageNameRouteType[] = [
    AppPageNameRoutes.CODETABLES_SERVICE_PACKAGES
];

type ServicePackageFilterType = {
    nameLike: string | null,
    state: ServicePackageStateEnum | null
}

const initState = {
    filter: {nameLike: '', state: ServicePackageStateEnum.Active},
    spToEdit: {id: null, name: '', items: []}
}


export default function ServicePackageOverview() {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const { loading: loadingServicePackages, data: gqlServicePackages, error: errorServicePackages, refetch: refetchSPs} = useGetServicePackagesQuery({fetchPolicy: "network-only"});
    const [ servicePackages, setServicePackages] = useState<Array<ServicePackage>>();
    const [ filter, setFilter] = useState<ServicePackageFilterType>(initState.filter);
    const [ spDialogOpen, setSpDialogOpen] = useState<boolean>(false);
    const [ spToEdit, setSpToEdit] = useState<ServicePackageDto | null>(null);
    // Mutations
    const [updateServicePackageState, {loading: updateServicePackageStateLoading}] = useUpdateServicePackageStateMutation();
    const [updateServicePackage, {loading: updateServicePackageLoading}] = useUpdateServicePackageMutation();
    const [createServicePackage, {loading: createServicePackageLoading}] = useCreateServicePackageMutation();


    useEffect(() => {
        if (gqlServicePackages) {
            setServicePackages(gqlServicePackages.getServicePackages);
        }
    }, [gqlServicePackages]);

    useEffect(() => {
        dispatch(setLoadingStatus(loadingServicePackages || updateServicePackageStateLoading));
    }, [loadingServicePackages, updateServicePackageStateLoading, dispatch]);

    const handleFilterNameLike = (event: React.ChangeEvent<{ name?: string; value: string }>) => {
        const newFilter: ServicePackageFilterType = {
            nameLike: event.target.value,
            state: filter.state,
        }
        setFilter(newFilter);
    }

    const handleFilterState = (value: ServicePackageStateEnum) => {
        const newFilter: ServicePackageFilterType = {
            nameLike: filter.nameLike,
            state: value,
        }
        setFilter(newFilter);
    }

    const displayGqlError = (error: GraphQLFormattedError) => {
        dispatch(openErrorSnackbar(getGQLErrorMessages(error)));
    }

    const displayGqlErrors = (errors: ReadonlyArray<GraphQLFormattedError>) => {
        errors.forEach(displayGqlError);
    }

    const displayBusinessError = (error: BusinessError) => {
        dispatch(openErrorSnackbar(getBusinessErrorMessages(error, t, "codetable.servicePackage")));
    }

    const displaySuccessMessage = (msgKey: string, translationOptions?: Record<string, string | number | boolean | TFunctionResult | null | undefined>) => {
        dispatch(
            openSuccessSnackbar(
                t(msgKey, translationOptions,
                )
            ),
        );
    }

    const updateState = (servicePackageId: string, state: ServicePackageStateEnum) => {
        if (servicePackageId && state){
            let mutOptions: UpdateServicePackageStateMutationOptions = {
                variables: {
                    input: {
                        id: servicePackageId,
                        state: state,
                    }
                }
            };
            updateServicePackageState(mutOptions)
                .then(response => {
                    if (response?.data?.updateServicePackageState?.servicePackage) {
                        displaySuccessMessage(
                            "userMessages.codetable.servicePackage.stateUpdated",
                            {
                                state: t("servicePackage.state.values." + response.data.updateServicePackageState.servicePackage.state),
                            },
                        );
                    } else if (response?.data?.updateServicePackageState?.errors) {
                        response.data.updateServicePackageState.errors.forEach(displayBusinessError);
                    } else if(response.errors){
                        displayGqlErrors(response.errors);
                    }
                })
                .catch(error => <ApolloError error={error}/>)
                .finally(() => {
                    refetchSPs()
                        .then((d) => d.data)
                });
        } else {
            throw Error('Missing one or more required params: serviceId: ' + servicePackageId + ', state: ' + state);
        }

    }

    const handleUpdate = (servicePackage: ServicePackageDto) => {
        if (servicePackage.id && servicePackage.name && servicePackage.items) {
            const mutOptions: UpdateServicePackageMutationOptions = {
                variables: {
                    input: {
                        id: servicePackage.id,
                        name: servicePackage.name,
                        items: servicePackage.items
                    }
                }
            }

            updateServicePackage(mutOptions)
                .then(response => {
                    if (response?.data?.updateServicePackage?.servicePackage) {
                        displaySuccessMessage(
                            "userMessages.codetable.servicePackage.updated",
                            {
                                servicePackageId: response.data.updateServicePackage.servicePackage.id,
                            },
                        );
                        handleCloseDialog();
                    } else if (response?.data?.updateServicePackage?.errors) {
                        response.data.updateServicePackage.errors
                            .forEach(displayBusinessError);
                    } else if(response.errors){
                        displayGqlErrors(response.errors);
                    }
                })
                .catch(error => <ApolloError error={error}/>)
                .finally(() => {
                    refetchSPs()
                        .then((d) => d.data)
                });
        } else {
            throw Error(`Missing one or more required params: serviceId: ${servicePackage.id}, name: ${servicePackage.name}, itemsCount: ${servicePackage.items.length}`);
        }
    }

    const handleCreate = (servicePackage: ServicePackageDto) => {
        if (servicePackage.name && servicePackage.items) {

            const mutOptions: CreateServicePackageMutationOptions = {
                variables: {
                    input: {
                        name: servicePackage.name,
                        items: servicePackage.items
                    }
                }
            }
            createServicePackage(mutOptions)
            .then(response => {
                if (response?.data?.createServicePackage?.servicePackage) {
                    displaySuccessMessage(
                        "userMessages.codetable.servicePackage.created",
                        {servicePackageId: response.data.createServicePackage.servicePackage.id},
                    );
                    handleCloseDialog();
                } else if (response?.data?.createServicePackage?.errors) {
                    response.data.createServicePackage.errors.forEach(displayBusinessError);
                } else if(response.errors){
                    displayGqlErrors(response.errors);
                }
            })
                .catch(error => <ApolloError error={error}/>)
                .finally(() => {
                    refetchSPs()
                        .then((d) => d.data)
                });

        } else {
            throw Error(`Missing one or more required params: serviceId: ${servicePackage.id}, name: ${servicePackage.name}, itemsCount: ${servicePackage.items.length}`);
        }
    }

    const handleSave = (servicePackage: ServicePackageDto) => {
        if (servicePackage.id){
            handleUpdate(servicePackage);
        } else {
            handleCreate(servicePackage);
        }
    }

    const handleCloseDialog = () => {
        setSpToEdit(null);
        setSpDialogOpen(false);
    }

    const openEditorDialog = (sp?: ServicePackage) => {
        sp ? setSpToEdit({ id: sp.id, name: sp.name ? sp.name : '', items: sp.services}) : setSpToEdit(initState.spToEdit);
        setSpDialogOpen(true);
    }

    if (errorServicePackages) return <ApolloError error={[errorServicePackages]} />;

    return (
        <Container className="page service-package" >
            <Grid justifyContent={"space-between"}>
                <Grid
                    item
                    className="text-left"
                >
                    {hasRole(Role.CODETABLE_ADMIN.toString()) &&
                    <Button startIcon={<Add/>} className="button new-service-package"
                            onClick={() => openEditorDialog()}>
                        {t("pages.codetables.servicePackage.addNewServicePackage")}
                    </Button>
                    }
                    <PageHeader headline={"servicePackages"} breadcrumbs={breadcrumbs}/>
                </Grid>

            </Grid>

            <Grid className="filter__holder">
                <Box mb={3}>
                    <Typography variant="h5" className="text-left">
                        {t("pages.definitionTable.filters.title")}
                    </Typography>
                </Box>
                <Grid container direction="row" justifyContent="flex-start" >
                    <Grid item className="text-left" >
                        <FormControl variant="outlined">
                            <TextField
                                id={"sp-name-like-filter"}
                                label={t("fieldName.name")}
                                type={"text"}
                                name={"filter.nameLike"}
                                value={filter.nameLike}
                                onChange={handleFilterNameLike}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <EnumSelect
                            inputValues={servicePackageStates}
                            label={t("fieldName.state")}
                            enumLabelsPath="servicePackage.state.values"
                            filteredValues={[filter.state]}
                            multiselect={false}
                            setFilteredValues={handleFilterState}

                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid className="filter__holder" >
                {renderServicePackages(servicePackages, filter, t, updateState, openEditorDialog)}
            </Grid>
            {spToEdit &&
                <ServicePackageDialog
                    inputServicePackage={spToEdit}
                    open={spDialogOpen}
                    handleClose={handleCloseDialog}
                    handleUpdate={handleSave}
                />}
        </Container>
    )
}

const renderServicePackages = (servicePackagesInput: Array<ServicePackage> | undefined, filter: ServicePackageFilterType, translator: Function, updateState: Function, openEditorDialog: Function) => {

    const servicePackages: Array<ServicePackage> | undefined = servicePackagesInput?.filter((sp) => {
        let result: boolean = true;
        if (filter){
            if (filter.state && filter.state !== sp.state){
                result = false;
            }

            if(filter.nameLike && !(sp.name?.toLocaleLowerCase().includes(filter.nameLike.toLocaleLowerCase()))){
                result = false;
            }
        }

        return result;
    }).sort((a, b) => {
        if (!a.name) {return 1;}
        if (!b.name) {return -1;}
        if(a.name < b.name) { return -1; }
        if(a.name > b.name) { return 1; }
        return 0;
    });

    if(servicePackages && servicePackages.length > 0){
        return (
            <Grid container justifyContent="flex-start" direction="row" xs={1} sm={6} md={9} lg={12} >
                {servicePackages
                    .map(sp => {
                        return <Grid item direction={'column'}>
                            {renderServicePackageCard(sp, updateState, translator, openEditorDialog)}
                        </Grid>
                    })}
            </Grid>
        );
    } else {
        return <h5>{translator('userMessages.noDataFoundFilter')}</h5>
    }
}

const renderServicePackageCard = (sp: ServicePackage, updateState: Function, translate: Function, openEditorDialog: Function) => {
    const archiveTooltip: string = translate("actions.archive");
    const activateTooltip: string = translate("actions.restore");
    const editButtonTitle: string = translate("actions.edit");
    const cardHeaderColorClass: string = sp.state === ServicePackageStateEnum.Archived ? 'inactive-background' : 'active-background';
    return (
        <Card className={"w-350-px h-100 list-card"} variant={'outlined'}>
            <CardHeader title={sp.name} className={`${cardHeaderColorClass} `} titleTypographyProps={{variant:'h6'}}/>
            <CardContent>
                <List>
                    {sp.services.map((service) => {return (<ListItem>{service}</ListItem>)})}
                </List>
            </CardContent>
            {hasRole(Role.CODETABLE_ADMIN.toString()) &&
            <CardActions>
                <Grid container justifyContent={"flex-end"}>
                    {sp.state !== ServicePackageStateEnum.Archived &&
                    <Tooltip title={archiveTooltip}>
                        <IconButton onClick={() => updateState(sp.id, ServicePackageStateEnum.Archived)}>
                            <ArchiveOutlined/>
                        </IconButton>
                    </Tooltip>
                    }
                    {sp.state === ServicePackageStateEnum.Archived &&
                    <Tooltip title={activateTooltip}>
                        <IconButton onClick={() => updateState(sp.id, ServicePackageStateEnum.Active)}>
                            <UnarchiveOutlined/>
                        </IconButton>
                    </Tooltip>
                    }

                    <Tooltip title={editButtonTitle}>
                        <IconButton onClick={event => openEditorDialog(sp)}>
                            <EditOutlined/>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </CardActions>
            }
        </Card>

    )
}
